import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NewPlanState } from '../../pages/NewPlan';
import { AddOutlined, ArrowBack } from '@mui/icons-material';
import RacesAutocomplete from './RacesAutocomplete';
import Alert from '@mui/material/Alert';
import { AppContext } from '../../contexts/AppContext';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Plan } from '../../services/PlansService';
import { useIntl } from 'react-intl';
export interface NewPlanRaceProps {
    onStateChange: (newState: NewPlanState) => void;
    setPlanData: (newPlanData: Partial<Plan>) => void;
    plan: Partial<Plan>;
}

const NewPlanRace: React.FC<NewPlanRaceProps> = ({ onStateChange, setPlanData, plan }) => {

    const intl = useIntl();

    const [error, setError] = React.useState<string | null>(null);

    const { racesService, userService } = React.useContext(AppContext);

    React.useEffect(() => {
        if (plan.raceId) {
            const race = racesService.races.find(r => r.id === plan.raceId);
            if (race) {
                setPlanData({
                    raceId: race?.id,
                    date: race?.date,
                    gpxFile: race?.gpxFiles?.sort((a, b) => b.date.getTime() - a.date.getTime())[0],
                    distance: race?.distance,
                    elevationGain: race?.elevationGain,
                    elevationLoss: race?.elevationLoss
                })
            }
        }
    }, []);

    const handleAddRace = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('raceName') || !data.get('raceWebsite') || !data.get('raceDistance')) {
            setError("error.allFieldsRequired");
            return;
        }

        try {
            await racesService.addRaceRequest({
                raceName: data.get('raceName') as string,
                raceWebsite: data.get('raceWebsite') as string,
                raceDistance: data.get('raceDistance') as string,
                userId: userService.user?.id || "",
                userEmail: userService.user?.email || "",
                createdAt: new Date()
            });
            onStateChange("add_race_confirmation");
        } catch (error) {
            setError("error.raceAddition");
        }
    };

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => onStateChange("type")}>
                {intl.formatMessage({ id: "nav.back" })}
            </Button>
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.newPlanTypeRace" })}
            </Typography>
            <Box sx={{ mt: 1 }}>
                <RacesAutocomplete onChooseRace={(race) => setPlanData({
                    raceId: race?.id,
                    date: race?.date,
                    gpxFile: race?.gpxFiles?.sort((a, b) => b.date.getTime() - a.date.getTime())[0],
                    distance: race?.distance,
                    elevationGain: race?.elevationGain,
                    elevationLoss: race?.elevationLoss
                })} selectedRaceId={plan.raceId} />
                {plan.raceId && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                            label={intl.formatMessage({ id: "form.raceDate" })}
                            sx={{ mt: 2, mb: 1, width: "100%" }}
                            value={plan.date ? dayjs(plan.date) : undefined}
                            onChange={(newValue) => setPlanData({ date: newValue?.toDate() })}
                        />
                    </LocalizationProvider>)
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!plan.raceId}
                    onClick={() => onStateChange("divide")}
                >
                    {intl.formatMessage({ id: "nav.next" })}
                </Button>
            </Box>

            <Typography variant="body2" sx={{ mt: 2 }}>
                <strong>{intl.formatMessage({ id: "plans.newPlanAddRace1" })}</strong>
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
                <strong>{intl.formatMessage({ id: "plans.newPlanAddRace2" })}</strong>
            </Typography>
            <Box component="form" onSubmit={handleAddRace} noValidate>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="raceName"
                    label={intl.formatMessage({ id: "form.raceName" })}
                    name="raceName"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="raceWebsite"
                    label={intl.formatMessage({ id: "form.raceWebsite" })}
                    name="raceWebsite"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="raceDistance"
                    label={intl.formatMessage({ id: "form.raceDistance" })}
                    name="raceDistance"
                    onChange={() => setError(null)}
                />

                {
                    error && <Alert severity="error">{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" }) })}</Alert>
                }
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                >
                    {intl.formatMessage({ id: "plans.addRace" })}
                </Button>
            </Box>
        </Box >
    );
};

export default NewPlanRace;