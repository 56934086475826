import { FeatureCollection, Feature } from 'geojson';
import { Point } from '../types/visualization';
import { getSteepType } from './UnitsUtils';

export const pointsToGeojsonLine = (points: Point[], latLng: [number, number][]): FeatureCollection => {
    
    const features: Feature[] = [];

    let currentCoordinates = [];
    for (let i = 0; i < points.length; i++) {
        currentCoordinates.push(latLng[i]);
        if (i === points.length - 1 || getSteepType(points[i].slope) !== getSteepType(points[i + 1].slope)) {
            features.push({
                'type': 'Feature',
                'properties': {
                    'slope': getSteepType(points[i].slope)
                },
                'geometry': {
                    'coordinates': i < points.length - 1 ? [...currentCoordinates, latLng[i + 1]] : currentCoordinates,
                    'type': 'LineString'
                }
            });
            currentCoordinates = [];
        }
    }

    return {
        'type': "FeatureCollection",
        'features': features
    };
}