import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NewPlanState } from '../../pages/NewPlan';
import { AddOutlined, WorkspacePremium } from '@mui/icons-material';
import { Plan } from '../../services/PlansService';
import { useIntl } from 'react-intl';
import { AppContext } from '../../contexts/AppContext';
import { observer } from 'mobx-react-lite';
export interface NewPlanTypeProps {
    onStateChange: (newState: NewPlanState) => void;
    setPlanData: (newPlanData: Partial<Plan>) => void;
    plan: Partial<Plan>;
}

const NewPlanType: React.FC<NewPlanTypeProps> = observer(({ onStateChange, setPlanData, plan }) => {

    const intl = useIntl();

    const { userService } = React.useContext(AppContext);

    if (!userService.user) {
        return null;
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.newPlan" })}
            </Typography>
            {userService.user.newUser && <Typography variant="body2" textAlign="center" marginTop={1}>
                {
                    intl.formatMessage({ id: "data.newUser" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>{userService.user?.tokens} <WorkspacePremium /></Box>, b: (chunks: React.ReactNode) => <strong>{chunks}</strong> })
                }
            </Typography>}
            <Box sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="planName"
                    label={intl.formatMessage({ id: "form.planName" })}
                    name="planName"
                    value={plan.name || ""}
                    autoFocus
                    onChange={(event) => setPlanData({ name: event.target.value })}
                />
                <Typography variant="body2" sx={{ my: 2 }}>
                    <strong>{intl.formatMessage({ id: "plans.planTypeSub" })}</strong>
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!plan.name}
                    onClick={() => {
                        setPlanData({ type: "race" });
                        onStateChange("race");
                    }}
                >
                    {intl.formatMessage({ id: "plans.planTypeRace" })}
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!plan.name}
                    onClick={() => {
                        setPlanData({ type: "own" });
                        onStateChange("own");
                    }}
                >
                    {intl.formatMessage({ id: "plans.planTypeOwn" })}
                </Button>
            </Box>
        </Box >
    );
});

export default NewPlanType;