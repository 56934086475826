import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import { styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import { pointsToGeojsonLine } from "../../utils/pointsToGeojsonLine";
import { getLatLngForDistance } from "../../utils/UnitsUtils";

const MapContainer = styled('div')(({ theme }) => ({
    height: '100%',
}));

interface MapProps {
    bbox?: [number, number, number, number];
}

export const Map = observer(({ bbox }: MapProps) => {

    const { mapService, plansService } = useContext(AppContext);

    useEffect(() => {
        return () => {
            mapService.dispose();
        }
    }, []);

    useEffect(() => {
        if (mapService.loaded && mapService.map && plansService.elevationSeries && plansService.latlng && plansService.sections) {
            mapService.addPolyline(pointsToGeojsonLine(plansService.elevationSeries, plansService.latlng));

            const sectionMapPoints: [number, number][] = plansService.sections.map((section) => {
                const point = getLatLngForDistance(section, plansService.latlng, plansService.distancePoints);
                return [point[0], point[1]];
            });
            const first: [number, number] = [plansService.latlng[0][0], plansService.latlng[0][1]];
            const last: [number, number] = [plansService.latlng[plansService.latlng.length - 1][0], plansService.latlng[plansService.latlng.length - 1][1]];
            mapService.addWaypoints([first, ...sectionMapPoints, last]);
        }
    }, [mapService.loaded, mapService.map, mapService, plansService.elevationSeries, plansService.latlng, plansService.sections, plansService.distancePoints]);

    return (
        <MapContainer id="map">
        </MapContainer>
    );
});