export const upHillColor = "#453C67";
export const flatColor = "#6D67E4";
export const downhillColor = "#46C2CB";
export const otherColor = "#404258"

export const noInclineColor = "#44ce1b";
export const moderateInclineColor = "#bbdb44";
export const steepInclineColor = "#f7e379";
export const verySteepInclineColor = "#f2a134";
export const extremelySteepInclineColor = "#e51f1f"

export const standardColor = "#000000";