import { observer } from "mobx-react-lite";
import useRestricted from "../hooks/useRestricted";

const Search: React.FC = observer(() => {

  useRestricted();

  return (
    <div>
      Search
    </div>
  );
});

export default Search;
