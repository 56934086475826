import { Box, Tab, Typography, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { NutritionProductPlan, PlannedNutrition, SectionData } from "../../services/PlansService";
import { convertDistance, convertElevation, round } from "../../utils/UnitsUtils";
import { useContext, useEffect, useState } from "react";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { AppContext } from "../../contexts/AppContext";

dayjs.extend(localizedFormat);

const ParagraphLine = styled("span")(({ theme }) => ({
    verticalAlign: "baseline",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: "0.5em",
    marginRight: "0.5em"
}));

const ArrowLine = styled("span")(({ theme }) => ({
    verticalAlign: "center",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    marginLeft: "0.5em",
}));

const ArrowPoint = styled("span")(({ theme }) => ({
    width: 0,
    height: 0,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: `10px solid ${theme.palette.grey[400]}`,
    marginRight: "0.5em"
}));
export interface SectionTableProps {
    sectionData: SectionData;
}

const SectionTable: React.FC<SectionTableProps> = observer(({ sectionData }) => {

    const intl = useIntl();

    const { userService } = useContext(AppContext);

    const distanceUnits = userService.user?.distanceUnits || "km";
    const elevationUnits = userService.user?.elevationUnits || "m";

    return (
        <>
            <Box marginY={1} paddingX={2}>
                <Box display="flex" alignItems="center" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.startDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                    <ArrowLine />
                    <ArrowPoint />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.endDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.distance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.minElevationTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.minElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>
                <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                    <Typography variant="body2">{intl.formatMessage({ id: "data.maxElevationTitle" })}</Typography>
                    <ParagraphLine />
                    <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.maxElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                </Box>

                {sectionData.beforeDistance > 0 && (<>
                    <Box textAlign="center">
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.beforeTitle" })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between">
                        <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.beforeDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.beforeElevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.beforeElevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                </>)}
                {sectionData.afterDistance > 0 && (<>
                    <Box textAlign="center">
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.afterTitle" })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between">
                        <Typography variant="body2">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(sectionData.afterDistance / 1000, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">+{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.afterElevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                    <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                        <Typography variant="body2">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
                        <ParagraphLine />
                        <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(sectionData.afterElevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
                    </Box>
                </>)}


            </Box>
        </>
       
            );
});

            export default SectionTable;