import { Box, IconButton, Typography, styled } from "@mui/material";
import { useIntl } from "react-intl";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Plan } from "../../services/PlansService";

const SectionChangerBox = styled(Box)(({ theme }) => ({
  borderBottom: `solid 1px ${theme.palette.divider}`
}));

interface SectionChangerProps {
    currentSection: number;
    changeSection: (section: number) => void;
    plan: Plan;
}

export const SectionChanger = ({ currentSection, changeSection, plan }: SectionChangerProps) => {

    const intl = useIntl();
    
    return (
        <SectionChangerBox display="flex" alignItems="center" justifyContent="space-between" paddingY={1} paddingX={2}>
          <IconButton onClick={() => changeSection(currentSection - 1)} size="small" disabled={currentSection === 0}>
            <ChevronLeft fontSize="small" />
          </IconButton>
          <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.section" }, { num: currentSection + 1, total: plan.sections.length })}</Typography>
          <IconButton onClick={() => changeSection(currentSection + 1)} size="small" disabled={currentSection === plan.sections.length - 1}>
            <ChevronRight fontSize="small" />
          </IconButton>
        </SectionChangerBox>
    );
};