import { DirectionsRun, Help, Person, Search, WorkspacePremium } from "@mui/icons-material";
import { Box, Drawer, List, ListItem, ListItemButton, ListItemIcon, Tooltip, styled, useMediaQuery, useTheme } from "@mui/material";
import { useIntl } from "react-intl";
import { Link, Outlet, useLocation } from "react-router-dom";
import Logo from "../components/common/Logo";

const Main = styled('main')(({ theme }) => ({
  marginLeft: theme.spacing(8),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
    marginTop: theme.spacing(8),
  },
}));

const NavIcon = styled(ListItemIcon)<{ selected?: boolean }>(({ theme, selected }) => ({
  minWidth: 0,
  justifyContent: 'center',
  color: selected ? theme.palette.primary.main : theme.palette.text.secondary,
}));

export interface INavigationOption {
  name: string;
  href: string;
  icon: React.ElementType;
  routes: string[];
  index?: boolean;
};

const NavListItem: React.FC<INavigationOption> = (navigationOption) => {

  const location = useLocation();
  const currentPath = location?.pathname;

  return (
    <Tooltip title={navigationOption.name} placement="right" arrow>
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          sx={{
            minHeight: 48,
            justifyContent: 'center',
            px: 2.5,
          }}
          component={Link}
          to={navigationOption.href}
        >
          <NavIcon selected={(navigationOption.index && currentPath === "/") || navigationOption.routes.some(route => currentPath.includes(route))}>
            {navigationOption.icon && <navigationOption.icon />}
          </NavIcon>
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );

}

function Root() {

  const intl = useIntl();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const navigationOptions = {
    main: [
      { name: intl.formatMessage({ id: "nav.plans" }), href: '/plans', icon: DirectionsRun, routes: ['/plans'], index: true },
      { name: intl.formatMessage({ id: "nav.checkout" }), href: '/checkout', icon: WorkspacePremium, routes: ['/checkout'] }
      //{ name: intl.formatMessage({ id: "nav.search" }), href: '/search', icon: Search, routes: ['/search'] },
    ],
    secondary: [
      { name: intl.formatMessage({ id: "nav.help" }), href: '/help', icon: Help, routes: ['help'] },
      { name: intl.formatMessage({ id: "nav.account" }), href: '/account', icon: Person, routes: ['/account', '/sign-in', 'sing-up', 'forgot-password'] },
    ],
  };

  return (
    <Box>
      <Drawer variant="permanent" anchor={isUpMD ? "left" : "top"} sx={{ display: 'flex' }}
        PaperProps={{ sx: { flexDirection: isUpMD ? "column" : "row", justifyContent: "space-between", alignItems: "center" } }}
      >
        <List sx={{ display: isUpMD ? "block" : "flex" }}>
          {navigationOptions.main.map((navigationOption) => (
            <NavListItem key={navigationOption.name} {...navigationOption} />
          ))}
        </List>
        {
          !isUpMD && (
            <Logo width={80} />
          )
        }
        <List sx={{ marginTop: 'auto', display: isUpMD ? "block" : "flex" }}>
          {navigationOptions.secondary.map((navigationOption) => (
            <NavListItem key={navigationOption.name} {...navigationOption} />
          ))}
        </List>
      </Drawer>
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}

export default Root;
