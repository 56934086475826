import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { NutritionProductPlan, Plan, SectionData } from '../../services/PlansService';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TextField, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { Check, Delete, Edit } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import SectionTable from './SectionTable';
import { convertDistance, convertElevation, round } from '../../utils/UnitsUtils';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import TimeBox from './TimeBox';
import NutritionBox from './NutritionBox';
import { SectionChanger } from '../common/SectionChanger';

dayjs.extend(localizedFormat);

const TempoPlannerContainer = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  height: "calc(100vh - 164px)",
  [theme.breakpoints.down('md')]: {
    height: "auto",
  }
}));

const TopBox = styled(Box)(({ theme }) => ({
  //position: "sticky",
  //top: 0,
  borderBottom: `solid 1px ${theme.palette.divider}`
}));

const Col = styled(Box)(({ theme }) => ({
  width: 400,
  overflowY: "auto",
  "&:not(:last-child)": {
    borderRight: `solid 1px ${theme.palette.divider}`,
  },
  [theme.breakpoints.down('md')]: {
    width: "100%",
  }
}));

export interface TempoPlannerProps {
  plan: Plan;
  setPlanData: (newPlanData: Plan) => void;
  currentSection: number;
  changeSection: (section: number) => void;
  sectionsData: SectionData[];
  plot?: React.ReactNode;
}

const TempoPlanner: React.FC<TempoPlannerProps> = observer(({ plan, setPlanData, currentSection, changeSection, sectionsData, plot }) => {

  const intl = useIntl();
  const navigate = useNavigate();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { userService, plansService } = React.useContext(AppContext);

  const distanceUnits = userService.user?.distanceUnits || "km";
  const elevationUnits = userService.user?.elevationUnits || "m";

  const [editingName, setEditingName] = React.useState<boolean>(false);
  const [currentName, setCurrentName] = React.useState<string>(plan.name || "");
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);

  const handleSaveName = () => {
    setPlanData({ ...plan, name: currentName });
    setEditingName(false);
  }

  const handleDelete = () => {
    setDeleteOpen(false);
    if (!userService.user?.id || !plan.id) {
      return;
    }

    plansService.deletePlan(userService.user.id, plan.id).then(() => {
      navigate("/plans");
    });
  }

  const setSectionTime = (time: number) => {
    let newPlannedEffort = [...plan.plannedEffort];
    if (!plan.plannedEffort || plan.plannedEffort.length !== plan.sections.length) {
      newPlannedEffort = new Array(plan.sections.length).fill({ time: 0, rpe: 0 });
    }
    newPlannedEffort[currentSection] = { time, rpe: 0 };
    setPlanData({ ...plan, plannedEffort: newPlannedEffort });
  }

  const setSectionNutrition = (sectionNutrition: NutritionProductPlan[]) => {
    let newNutrition = [...(plan.plannedNutrition ? plan.plannedNutrition : [])];
    if (!plan.plannedNutrition || plan.plannedNutrition.length !== plan.sections.length) {
      newNutrition = new Array(plan.sections.length).fill([]);
    }
    newNutrition[currentSection] = { products: sectionNutrition };
    setPlanData({ ...plan, plannedNutrition: newNutrition });
  }

  const totalTime = plan.plannedEffort.reduce((acc, effort) => acc + effort.time, 0);
  const totalTimeBefore = plan.plannedEffort.slice(0, currentSection).reduce((acc, effort) => acc + effort.time, 0);

  const sectionTime = plan.plannedEffort[currentSection]?.time || 0;
  const [hours, setHours] = React.useState<number>(sectionTime ? Math.floor(sectionTime / 60) : 0);
  const [minutes, setMinutes] = React.useState<number>(sectionTime ? sectionTime % 60 : 0);

  React.useEffect(() => {
    const sectionTime = plan.plannedEffort[currentSection]?.time || 0;
    setHours(sectionTime ? Math.floor(sectionTime / 60) : 0);
    setMinutes(sectionTime ? sectionTime % 60 : 0);
  }, [currentSection, plan.plannedEffort]);

  const onChangeMinutes = (minutes: number) => {
    setSectionTime(((hours || 0) * 60) + minutes);
    setMinutes(minutes);
  }

  const onChangeHours = (hours: number) => {
    setSectionTime((hours * 60) + (minutes || 0));
    setHours(hours);
  }

  return (

    <Box>

      <TopBox>
        <Box display="flex" alignItems="center" paddingX={2} paddingTop={2}>
          {editingName ?
            <Box marginRight={1}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="planName"
                label={intl.formatMessage({ id: "form.planName" })}
                name="planName"
                value={currentName}
                autoFocus
                onChange={(event) => setCurrentName(event.target.value)}
              />
            </Box> :
            (<Typography variant="h6" marginRight={1}>{plan.name}</Typography>)}
          {editingName ?
            (<IconButton onClick={handleSaveName} disabled={!currentName} size="small">
              <Check fontSize="small" />
            </IconButton>) :
            (<IconButton onClick={() => setEditingName(true)} size="small">
              <Edit fontSize="small" />
            </IconButton>)}

          <Box marginLeft="auto" display="flex" alignItems="center">
            <IconButton onClick={() => setDeleteOpen(true)} size="small">
              <Delete fontSize="small" />
            </IconButton>
          </Box>
        </Box>


        <Box marginTop={1} marginBottom={1} paddingX={2} display={isUpMD ? "flex" : "grid"} gridTemplateColumns="auto auto" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.distanceTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(plan.distance, 1), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationGainTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
          </Box>
          <Box>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.elevationLossTitle" })}</Typography>
            <Typography variant="body2">{intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(plan.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })}</Typography>
          </Box>
          <Grid item xs={6} marginY={1}>
            <Typography variant="body2" fontSize={12} fontWeight="700">{intl.formatMessage({ id: "data.dateTitle" })}</Typography>
            <Typography variant="body2">{dayjs(plan.date).locale(intl.locale).format("lll")}</Typography>
          </Grid>
        </Box>
      </TopBox>

      <SectionChanger currentSection={currentSection} changeSection={changeSection} plan={plan} />

      <TempoPlannerContainer display={isUpMD ? "flex" : "block"}>

        <Col>

          {plot}

          {sectionsData.length > currentSection && <SectionTable
            sectionData={sectionsData[currentSection]}
          />}

        </Col>
        <Col>
            <TimeBox hours={hours} minutes={minutes} onChangeHours={onChangeHours} onChangeMinutes={onChangeMinutes} startDate={plan.date} totalTime={totalTime} totalTimeBefore={totalTimeBefore} />
            <NutritionBox totalTime={totalTime} totalNutrition={plan.plannedNutrition} sectionNutrition={plan.plannedNutrition && plan.plannedNutrition.length ? plan.plannedNutrition[currentSection]?.products || [] : []} setSectionNutrition={setSectionNutrition} />
        </Col>

        <Dialog
          open={deleteOpen}
          onClose={() => setDeleteOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({ id: "plans.deletePlan" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "plans.deletePlanConfirm" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDeleteOpen(false)}>
              {intl.formatMessage({ id: "action.cancel" })}
            </Button>
            <Button onClick={handleDelete} autoFocus>
              {intl.formatMessage({ id: "action.delete" })}
            </Button>
          </DialogActions>
        </Dialog>

      </TempoPlannerContainer>
    </Box>
  );
});

export default TempoPlanner;