import { makeAutoObservable } from "mobx";
import * as maptilersdk from '@maptiler/sdk';
import { FeatureCollection } from 'geojson';
import { extremelySteepInclineColor, moderateInclineColor, noInclineColor, steepInclineColor, verySteepInclineColor } from "../utils/ColorUtils";

export interface IMapStyle {
    id: string;
    name: string;
    image: string;
}

maptilersdk.config.apiKey = 'r0MEeCVGTMb6ZcYa0AFo';

export const styles: IMapStyle[] = [
    { id: "satellite", name: "Satellite", image: `${process.env.PUBLIC_URL}/images/satellite.png` },
    { id: "outdoor-v2", name: "Standard", image: `${process.env.PUBLIC_URL}/images/outdoor-v2.png` },
];

export default class MapService {

    get map() {
        return this._map;
    }

    get mapStyle() {
        return this._mapStyle;
    }

    get loaded() {
        return this._loaded;
    }

    private _map: maptilersdk.Map | null = null;
    private _mapStyle: IMapStyle = styles[0];
    private _loaded = false;
    private _bounds: [number, number, number, number] = [0, 0, 0, 0];

    private _polyline: FeatureCollection = {
        'type': "FeatureCollection",
        'features': []
    };
    private _waypoints: [number, number][] = [];

    constructor() {
        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    init(bounds: [number, number, number, number], showControls = true) {
        if (this._map) {
            this.dispose();
        }

        this._map = new maptilersdk.Map({
            container: 'map',
            style: this._mapStyle.id,
            bounds,
            zoom: 14,
            maptilerLogo: false,
            geolocateControl: false,
            terrain: true,
            terrainControl: showControls,
            navigationControl: showControls,
        });

        this._bounds = bounds;

        this._map.on('terrain', this.onTerrain);

        this._map.on('load', this.onLoad);

        this._map.on("styledata", this.onStyleData);
    }

    onTerrain() {
        if (this._map) {
            if (this._map.hasTerrain()) {
                this.changePitch(this._map.getMaxPitch());
            } else {
                this.changePitch(0);
            }
        }
    }

    onLoad() {
        if (this._map) {
            this._map.fitBounds(this._bounds, { padding: 20 });
            setTimeout(() => {
                if (this._map)
                    this._map.setMaxBounds(this._map.getBounds());
                this._loaded = true;
            }, 3000);
        }
    }

    onStyleData(e: maptilersdk.MapDataEvent) {
        if (this._map && this._polyline && this._waypoints) {
            this.addPolylineLayer();
            this.addWaypointsLayer();
            this.addPointLayer();
        }
    }

    dispose() {
        if (this._map) {
            this._map.off('load', this.onLoad);
            this._map.off('terrain', this.onTerrain);
            this._map.off('styledata', this.onStyleData);
            this._map.remove();
            this._map = null;
            this._loaded = false;
            this._polyline = {
                'type': "FeatureCollection",
                'features': []
            };
            this._waypoints = [];
        }
    }

    setMapStyle(mapStyleId: string) {
        const mapStyle = styles.find(s => s.id === mapStyleId);
        if (!mapStyle || mapStyle === this._mapStyle) {
            return;
        }
        this._mapStyle = mapStyle;
        if (this._map) {
            this._loaded = false;
            this._map.setStyle(mapStyle.id);
        }
    }

    addPolyline(geojson: FeatureCollection) {
        this._polyline = geojson;
        const source = this._map?.getSource('line');
        if (this._map && source) {
            (source as maptilersdk.GeoJSONSource).setData(geojson);
        }
    }

    addPolylineLayer() {

        if (this._map && !this._map?.getSource('line')) {
            this._map.addSource('line', {
                type: 'geojson',
                lineMetrics: true,
                data: this._polyline
            });

            this._map.addLayer({
                type: 'line',
                source: 'line',
                id: 'line',
                paint: {
                    'line-width': 4,
                    'line-color': [
                        'interpolate',
                        ['linear'],
                        ['get', 'slope'],
                        1,
                        noInclineColor,
                        2,
                        moderateInclineColor,
                        3,
                        steepInclineColor,
                        4,
                        verySteepInclineColor,
                        5,
                        extremelySteepInclineColor
                    ]
                },
                layout: {
                    'line-cap': 'butt',
                    'line-join': 'bevel'
                }
            });
        }
    }

    setPointPostion(lat: number, lng: number) {
        if (this._map) {
            const source = this._map.getSource('point');
            if (source) {
                (source as maptilersdk.GeoJSONSource).setData({
                    'type': 'Point',
                    'coordinates': [lng, lat]
                });
            }
        }
    }

    addPointLayer() {
        if (this._map && !this._map?.getSource('point')) {
            this._map.addSource('point', {
                'type': 'geojson',
                'data': {
                    'type': 'Point',
                    'coordinates': [0, 0]
                }
            });

            this._map.addLayer({
                'id': 'point',
                'source': 'point',
                'type': 'circle',
                'paint': {
                    'circle-radius': 4,
                    'circle-color': '#000000'
                }
            });
        }
    }

    addWaypoints(points: [number, number][]) {
        this._waypoints = points;

        const source = this._map?.getSource('waypoints');
        if (this._map && source) {
            (source as maptilersdk.GeoJSONSource).setData({
                'type': "FeatureCollection",
                'features': points.map((point, index) => {
                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': point
                        },
                        'properties': {
                            'type': index === 0 ? 'start' : index === points.length - 1 ? 'end' : 'waypoint',
                        }
                    };
                })
            });
        }
    }

    addWaypointsLayer() {
        if (this._map && !this._map?.getSource('waypoints')) {
            this._map.addSource('waypoints', {
                'type': 'geojson',
                'data': {
                    'type': "FeatureCollection",
                    'features': this._waypoints.map((point, index) => {
                        return {
                            'type': 'Feature',
                            'geometry': {
                                'type': 'Point',
                                'coordinates': point
                            },
                            'properties': {
                                'type': index === 0 ? 'start' : index === this._waypoints.length - 1 ? 'end' : 'waypoint',
                            }
                        };
                    })
                }
            });

            this._map.addLayer({
                'id': 'waypoints',
                'source': 'waypoints',
                'type': 'circle',
                'paint': {
                    'circle-radius': 4,
                    'circle-stroke-width': 2,
                    'circle-stroke-color': [
                        "match",
                        ["get", "type"],
                        "start", noInclineColor,
                        "#000000"
                    ],
                    'circle-color': [
                        "match",
                        ["get", "type"],
                        "start", noInclineColor,
                        "end", "#000000",
                        "transparent"
                    ]
                }
            });
        }
    }

    private changePitch(pitch: number, duration = 1000) {
        if (!this._map) {
            return;
        }

        const easeOptions = {
            pitch: pitch,
            duration: duration,
            easing: (x: number) => (x < 0.5 ? 16 * x ** 5 : 1 - (-2 * x + 2) ** 5 / 2)
        };
        this._map.easeTo(easeOptions);
    }
}