import { useContext, useEffect } from "react";
import { AppContext } from "../../contexts/AppContext";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { observer } from "mobx-react-lite";
import { Race } from "../../services/RacesService";
import { useIntl } from "react-intl";

const RacesAutocomplete: React.FC<{ onChooseRace: (race: Race | null) => void, selectedRaceId?: string }> = observer(({ onChooseRace, selectedRaceId }) => {

  const intl = useIntl();
  const { racesService } = useContext(AppContext);

  useEffect(() => {
    racesService.loadRaces();
  }, [racesService]);

  const options = racesService.races.filter(r => r.gpxFiles && r.gpxFiles.length > 0);

  const filterOptions = createFilterOptions({
    stringify: (option: Race) => option.main + " " + option.name,
  });
  
  return (
    <Autocomplete
      id="races-autocomplete"
      getOptionLabel={(option) => option.name}
      options={options}
      loading={racesService.isLoadingRaces}
      groupBy={(option) => option.main}
      filterOptions={filterOptions}
      value={selectedRaceId ? options.find(r => r.id === selectedRaceId) || null : null}
      onChange={(event: any, newValue: Race | null) => {
        onChooseRace(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={intl.formatMessage({ id: "form.chooseRace" })}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {racesService.isLoadingRaces ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
});

export default RacesAutocomplete;