import React, { useEffect } from "react";
import { Point } from "../../types/visualization";
import { Box } from "@mui/material";
import * as d3 from "d3";
import { convertDistance, convertElevation, getElevation, round } from "../../utils/UnitsUtils";
import { useIntl } from "react-intl";
import { AppContext } from "../../contexts/AppContext";

function resizeTextRect(text: any, rect: any) {
  if (!text || !text.node()) return;
  const { x, y, width: w, height: h } = text.node()!.getBBox();
  rect.attr("x", x - 5).attr("y", y - 5).attr("width", w + 10).attr("height", h + 10);
}

export type PlotType = "steep" | "slope" | "standard";
export type PlotRange = "full" | "section";

export type PlotContainerProps = {
  id: string,
  data: Point[];
  dimensions: { width: number; height: number; margin: { top: number; right: number; bottom: number; left: number } };
  breaks?: number[];
  setHoveredDistance?: (distance: number | null) => void;
  selectionBoxBottom?: boolean;
  insta?: boolean;
};

export const PlotContainer: React.FC<PlotContainerProps> = ({
  id,
  data,
  dimensions,
  breaks,
  setHoveredDistance,
  selectionBoxBottom,
  insta
}) => {

  const { userService } = React.useContext(AppContext);

  const distanceUnits = userService.user?.distanceUnits || "km";
  const elevationUnits = userService.user?.elevationUnits || "m";

  const intl = useIntl();

  const svgRef = React.useRef(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const { width: minWidth, height, margin } = dimensions;
  const svgWidth = + margin.left + margin.right;
  const svgHeight = height + margin.top + margin.bottom;

  useEffect(() => {
    if (!data || data.length === 0 || !svgRef.current || !containerRef.current) {
      return;
    }

    const width = containerRef.current?.clientWidth || minWidth - margin.left - margin.right;

    // Create root container where we will append all other chart elements
    const svg = d3.select(svgRef.current);
    svg.selectAll("*").remove();

    // Declare the x (horizontal position) scale.
    const x = d3.scaleLinear().domain(d3.extent(data.map(d => d.distance)) as [number, number]).range([margin.left, width - margin.right]);

    const elevations = data.map(d => d.elevation);
    // Declare the y (vertical position) scale.
    const y = d3.scaleLinear().domain([d3.min(elevations), d3.max(elevations)] as [number, number]).range([height - margin.bottom, margin.top]);

    // Declare the area generator.
    /* const area = d3.area<Point>()
      .x(d => x(d.distance))
      .y0(y(0))
      .y1(d => y(d.elevation)); */

    // Create the SVG container.
    svg
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [0, 0, width, height])
      .attr("style", "max-width: 100%; font: 10px sans-serif;")
      .style("-webkit-tap-highlight-color", "transparent")
      .style("overflow", "visible")

    svg.append("clipPath")
      .attr("id", "clip-" + id)
      .append("rect")
      .attr("width", width)
      .attr("height", height - margin.bottom)

    // Append a path for the area (under the axes).
    svg.append("path")
      .datum(data)
      .attr("clip-path", `url(#clip-${id})`)
      .attr("fill", "#44ce1b")
      .attr("d", d3.area<Point>()
        .x(function (d, i) { return x(d.distance) })
        .y0(function (d) { return y(0) })
        .y1(function (d, i) {
          if (
            Math.abs(d.slope) < 3 ||
            (i > 0 && Math.abs(data[i - 1].slope) < 3)
          ) { return y(d.elevation) } else { return y(0) }
        })
      );

    svg.append("path")
      .datum(data)
      .attr("clip-path", `url(#clip-${id})`)
      .attr("fill", "#bbdb44")
      .attr("d", d3.area<Point>()
        .x(function (d, i) { return x(d.distance) })
        .y0(function (d) { return y(0) })
        .y1(function (d, i) {
          if (
            (Math.abs(d.slope) < 7 && Math.abs(d.slope) >= 3) ||
            (i > 0 && Math.abs(data[i - 1].slope) < 7 && Math.abs(data[i - 1].slope) >= 3)
          ) { return y(d.elevation) } else { return y(0) }
        })
      );

    svg.append("path")
      .datum(data)
      .attr("clip-path", `url(#clip-${id})`)
      .attr("fill", "#f7e379")
      .attr("d", d3.area<Point>()
        .x(function (d, i) { return x(d.distance) })
        .y0(function (d) { return y(0) })
        .y1(function (d, i) {
          if (
            (Math.abs(d.slope) < 16 && Math.abs(d.slope) >= 7) ||
            (i > 0 && Math.abs(data[i - 1].slope) < 16 && Math.abs(data[i - 1].slope) >= 7)
          ) { return y(d.elevation) } else { return y(0) }
        })
      );

    svg.append("path")
      .datum(data)
      .attr("clip-path", `url(#clip-${id})`)
      .attr("fill", "#f2a134")
      .attr("d", d3.area<Point>()
        .x(function (d, i) { return x(d.distance) })
        .y0(function (d) { return y(0) })
        .y1(function (d, i) {
          if (
            (Math.abs(d.slope) < 25 && Math.abs(d.slope) >= 16) ||
            (i > 0 && Math.abs(data[i - 1].slope) < 25 && Math.abs(data[i - 1].slope) >= 16)
          ) { return y(d.elevation) } else { return y(0) }
        })
      );

    svg.append("path")
      .datum(data)
      .attr("clip-path", `url(#clip-${id})`)
      .attr("fill", "#e51f1f")
      .attr("d", d3.area<Point>()
        .x(function (d, i) { return x(d.distance) })
        .y0(function (d) { return y(0) })
        .y1(function (d) { if (Math.abs(d.slope) >= 25) { return y(d.elevation) } else { return y(0) } })
        .y1(function (d, i) {
          if (
            (Math.abs(d.slope) >= 25) ||
            (i > 0 && Math.abs(data[i - 1].slope) >= 25)
          ) { return y(d.elevation) } else { return y(0) }
        })
      );

    // Add the x-axis.
    svg.append("g")
      .attr("transform", `translate(0,${height - margin.bottom})`)
      .call(d3.axisBottom(x).ticks(width / 80).tickSizeOuter(0).tickFormat((d) => intl.formatMessage({ id: "data.valueUnit" }, { value: convertDistance(round(+d / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })));

    // Add the y-axis, remove the domain line, add grid lines and a label.
    svg.append("g")
      .attr("transform", `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).ticks(height / 40).tickFormat((e) => intl.formatMessage({ id: "data.valueUnit" }, { value: convertElevation(round(+e, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })))
      .call(g => g.select(".domain").remove())
      .call(g => g.selectAll(".tick line").clone()
        .attr("x2", width - margin.left - margin.right)
        .attr("stroke-opacity", 0.1))

    //aidStations
    svg.append('line').classed('hoverLine', true);

    const bisectDistance = d3.bisector<Point, number>(d => d.distance).right;

    if (breaks && breaks.length > 0) {
      for (let i = 0; i < breaks.length; i++) {
        const aidStation = breaks[i];
        svg.append('line').classed('aidStationLine' + i, true);
        const aidStationXIndex = bisectDistance(data, aidStation, 1);
        const aidStationElevation = data[aidStationXIndex].elevation;
        svg.selectAll('.aidStationLine' + i)
          .attr('x1', x(aidStation))
          .attr('y1', margin.top)
          .attr('x2', x(aidStation))
          .attr('y2', height - margin.bottom)
          .attr('stroke-dasharray', '5,5')
          .attr('stroke', '#000000')
          .attr('fill', '#000000')
          .attr('pointer-events', 'none')
          ;
      }
    }

    if (!insta) {
      // Create the brush behavior.
      const brush = d3.brushX<Point>().extent([[margin.left, margin.top], [width - margin.right, height - margin.bottom]]);
      svg.call(brush as any);

      svg.append("rect").classed("brushRect", true);
      svg.append("text").classed('brushText', true);

      brush.on("brush end", ({ selection }) => {
        if (selection) {

          const selectionX = selection.map(x.invert, x);

          const selectionDistance = selectionX[1] - selectionX[0];

          const elevations = getElevation(data.filter(d => d.distance >= selectionX[0] && d.distance <= selectionX[1]).map(d => d.elevation));

          const isLessThanHalf = selection[0] < width / 2;
          const brushTextX = isLessThanHalf ? selection[0] : selection[1];
          const brushTextDX = isLessThanHalf ? "1.25em" : "-1.25em";
          const brushTextAnchor = isLessThanHalf ? 'start' : 'end';
          const brushTextY = selectionBoxBottom ? height : -margin.top;

          const brushRect = svg.selectAll('.brushRect')
            .attr('x', brushTextX)
            .attr('y', brushTextY)
            .attr('fill', '#FFFFFF')
            .attr('pointer-events', 'none')
            .attr('stroke', '#000000');

          const brushText = svg.selectAll('.brushText')
            .attr('fill', "#000000")
            .style('text-anchor', brushTextAnchor)
            .data([,])
            .join("text")
            .call(text => text
              .selectAll("tspan")
              .data([intl.formatMessage({ id: "data.distance" }, { value: convertDistance(round(selectionDistance / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) }), intl.formatMessage({ id: "data.elevationGain" }, { value: convertElevation(round(elevations.elevationGain, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) }), intl.formatMessage({ id: "data.elevationLoss" }, { value: convertElevation(round(elevations.elevationLoss, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) })])
              .join("tspan")
              .attr("x", brushTextX)
              .attr("y", brushTextY)
              .attr('dx', brushTextDX)
              .attr('dy', (_, i) => `${(i * 1.25)}em`)
              .text(d => d));

          resizeTextRect(brushText, brushRect)
        } else {
          svg.selectAll('.brushText').attr('fill', "none");
          svg.selectAll('.brushRect').attr('fill', "none").attr('stroke', "none");
        }
      });

      brush.on("start", ({ selection }) => {
        if (!selection) {
          svg.selectAll('.brushText').attr('fill', "none");
          svg.selectAll('.brushRect').attr('fill', "none").attr('stroke', "none");
        }
      });

      //tooltip
      svg.append('circle').classed('hoverPoint', true);
      svg.append("rect").classed("hoverRect", true);
      svg.append("text").classed('hoverText', true);

      const mouseMove = (event: any) => {
        const mouseDistance = x.invert(d3.pointer(event)[0]);

        setHoveredDistance?.(mouseDistance);

        if (x(mouseDistance) < margin.left ||
          x(mouseDistance) > width - margin.right) {
          return;
        }

        const xIndex = bisectDistance(data, mouseDistance, 1);
        const mouseElevation = data.length > xIndex ? data[xIndex].elevation : 0;

        svg.selectAll('.hoverLine')
          .attr('x1', x(mouseDistance))
          .attr('y1', margin.top)
          .attr('x2', x(mouseDistance))
          .attr('y2', height - margin.bottom)
          .attr('stroke', '#000000')
          .attr('fill', '#000000')
          .attr('pointer-events', 'none')
          ;

        svg.selectAll('.hoverPoint')
          .attr('cx', x(mouseDistance))
          .attr('cy', y(mouseElevation))
          .attr('r', '4')
          .attr('fill', '#000000')
          .attr('pointer-events', 'none')
          ;

        const hoverRect = svg.selectAll('.hoverRect')
          .attr('x', x(mouseDistance))
          .attr('y', margin.top)
          .attr('fill', '#FFFFFF')
          .attr('pointer-events', 'none')
          .attr('stroke', '#000000')
          ;

        const isLessThanHalf = xIndex > data.length / 2;
        const hoverTextX = isLessThanHalf ? '-1.25em' : '1.25em';
        const hoverTextAnchor = isLessThanHalf ? 'end' : 'start';
        const hoverTextY = y(mouseElevation) < height / 2 ? 1.25 : -1.25;

        if (xIndex >= 0 && xIndex < data.length) {
          const hoverText = svg.selectAll('.hoverText')
            .attr('fill', "#000000")
            .style('text-anchor', hoverTextAnchor)
            //.text(formatElevation(mouseElevation));
            .data([,])
            .join("text")
            .call(text => text
              .selectAll("tspan")
              .data([intl.formatMessage({ id: "data.grade" }, { value: round(data[xIndex].slope, 0) }), intl.formatMessage({ id: "data.elevation" }, { value: convertElevation(round(mouseElevation, 0), elevationUnits), unit: intl.formatMessage({ id: elevationUnits }) }), intl.formatMessage({ id: "data.distance" }, { value: convertDistance(round(mouseDistance / 1000, 2), distanceUnits), unit: intl.formatMessage({ id: distanceUnits }) })])
              .join("tspan")
              .attr("x", x(mouseDistance))
              .attr("y", y(mouseElevation))
              .attr('dx', hoverTextX)
              .attr('dy', (_, i) => `${(i + 1) * hoverTextY}em`)
              .text(d => d));

          resizeTextRect(hoverText, hoverRect)
        }


      };
    

      svg.on('mousemove', mouseMove);
    }
  }, [data, height, margin.bottom, margin.left, margin.right, margin.top, breaks, intl, setHoveredDistance, insta]);

  return (
    <Box ref={containerRef} width="100%">
      <svg ref={svgRef} width={svgWidth} height={svgHeight} />
    </Box>
  );
};

