import { Layers } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Fab from "@mui/material/Fab";
import { styled } from "@mui/material/styles";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { AppContext } from "../../contexts/AppContext";
import { styles } from "../../services/MapService";

const StyleControlFab = styled(Fab)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(7),
}));

const StyleBox = styled(Box)(({ theme }) => ({
    top: theme.spacing(7),
    right: theme.spacing(7),
    borderRadius: "5px",
}));

const StyleImg = styled("img")<{selected: boolean}>(({ theme, selected }) => ({
    width: "100px",
    cursor: "pointer",
    outline: selected ? "2px solid" : "none",
    borderColor: theme.palette.primary.main,
    ":hover": {
        outline: "2px solid",
        borderColor: theme.palette.primary.main,
    },
}));

export const StyleControl = observer(() => {

    const { mapService } = useContext(AppContext);

    const [open, setOpen] = useState<boolean>(false);

    const handleStyleChange = (styleId: string) => {
        mapService.setMapStyle(styleId);
        setOpen(false);
    }

    return (
        <>
            <StyleControlFab size="small">
                <Layers onClick={() => setOpen(!open)} />
            </StyleControlFab>
            {open && <StyleBox display="flex" position="absolute" padding={1} bgcolor="white" boxShadow={2}>
                {
                    styles.map((style, index) =>
                        <Box key={style.id} marginRight={index === 0 ? 1 : 0} maxHeight={100}>
                            <StyleImg selected={mapService.mapStyle.id === style.id} src={style.image} alt={style.name} onClick={() => handleStyleChange(style.id)} />
                        </Box>
                    )
                }
            </StyleBox>}
        </>
    );
});