import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Copyright from './Copyright';
import { AppContext } from '../../contexts/AppContext';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { FirebaseError } from 'firebase/app';
import LoadingButton from '@mui/lab/LoadingButton';
import { useIntl } from 'react-intl';

const SignIn: React.FC = () => {

    const navigate = useNavigate();
    const intl = useIntl();

    const { userService } = React.useContext(AppContext);

    const location = useLocation();

    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleSignIn = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('email') || !data.get('password')) {
            setError("error.emailAndPasswordRequired");
            setLoading(false);
            return;
        }
        try {
            await userService.signIn(data.get('email') as string, data.get('password') as string, location.state?.from || "/");
        } catch (error) {
            if (error instanceof FirebaseError) {
                setError(error.code);
            } else {
                setError("error.unknown");
            }
            setLoading(false);
        }
    };

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "auth.signIn" })}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSignIn} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={intl.formatMessage({ id: "form.email" })}
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={intl.formatMessage({ id: "form.password" })}
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    onChange={() => setError(null)}
                />
                {
                    error && <Alert severity="error">{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" })})}</Alert>
                }
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    loading={loading}
                    sx={{ mt: 3, mb: 2 }}
                >
                    {intl.formatMessage({ id: "auth.signIn" })}
                </LoadingButton>
                <Grid container>
                    <Grid item xs textAlign="left">
                        <Link sx={{ cursor: "pointer" }} variant="body2" onClick={() => navigate("/forgot-password")}>
                            {intl.formatMessage({ id: "auth.forgotPassword" })}
                        </Link>
                    </Grid>
                    <Grid item textAlign="right">
                        <Link sx={{ cursor: "pointer" }} variant="body2" onClick={() => navigate("/sign-up")}>
                            {intl.formatMessage({ id: "auth.noAccountSignUp" })}
                        </Link>
                    </Grid>
                </Grid>
                <Copyright sx={{ mt: 5 }} />
            </Box>
        </Box>
    );
};

export default SignIn;