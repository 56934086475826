import * as React from 'react';
import { Link, Snackbar, Typography } from '@mui/material';
import ImageColumGrid from '../components/common/ImageColumGrid';
import NewPlanType from '../components/plans/NewPlanType';
import NewPlanRace from '../components/plans/NewPlanRace';
import useRestricted from '../hooks/useRestricted';
import { observer } from 'mobx-react-lite';
import NewPlanRaceRequestConfirmation from '../components/plans/NewPlanRaceRequestConfirmation';
import { Plan } from '../services/PlansService';
import { AppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import NewPlanDivideOwn from '../components/plans/NewPlanDivideOwn';
import NewPlanOwn from '../components/plans/NewPlanOwn';
import { getSlopesAndElevationSeriesFromGeojsonFeature } from '../utils/UnitsUtils';
import { Slope } from '../types/slope';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const toGeoJSON = require("@mapbox/togeojson");

export type NewPlanState = "type" | "race" | "add_race_confirmation" | "own" | "divide" | "finish";

const NewPlan: React.FC = observer(() => {

  useRestricted();

  const { userService, plansService } = React.useContext(AppContext);

  const navigate = useNavigate();

  const [state, setState] = React.useState<NewPlanState>("type");
  const [plan, setPlan] = React.useState<Partial<Plan>>({});
  const [isSavingPlan, setIsSavingPlan] = React.useState<NewPlanState>();
  const [error, setError] = React.useState(false);
  const [gpxFile, setGpxFile] = React.useState<File>();
  const [gpxSlopes, setGpxSlopes] = React.useState<Slope[]>([]);

  const handleStateChange = async (newState: NewPlanState) => {
    if (!userService.user?.id || !userService.user?.tokens) {
      return;
    }

    else if (newState === "race") {
      setGpxFile(undefined);
      setPlanData({ gpxFile: undefined, distance: undefined, elevationGain: undefined, type: "race" });
    }

    else if (newState === "own") {
      setPlanData({ raceId: "", gpxFile: undefined, distance: undefined, elevationGain: undefined, type: "own" });
    }
      
    else if (newState === "finish") {

      const now = new Date();
      const newPlan = {
        ...plan,
        userId: userService.user.id,
        id: `${plan.name?.toLowerCase().replaceAll(" ", "-")}-${now.getTime()}`, createdAt: now, modifiedAt: now, createdBy: userService.user?.id || "", plannedEffort: [],
      } as Plan;
      setPlan(newPlan);

      setIsSavingPlan("finish");
      try {
        if (gpxFile) {
          await plansService.addGpxFile("usersFiles", gpxFile, `${userService.user.id}-${newPlan.id}`);
        }
        await plansService.upsertPlan(userService.user.id, newPlan as Plan);
        await userService.updateUserData({ ...userService.user, tokens: (userService.user.tokens || 1) - 1, newUser: false });
        navigate(`/plans/${userService.user.id}/${newPlan.id}`);
      } catch (error) {
        console.error(error);
        setError(true);
      }
      setIsSavingPlan(undefined);
    }

    setState(newState);
  }

  const setPlanData = (newPlanData: Partial<Plan>) => {
    setPlan({ ...plan, ...newPlanData });
  }

  const handleSetGpxFile = (file: File) => {
    setGpxFile(file);

    const reader = new FileReader();
      reader.onload = (e) => {
        const parser = new DOMParser();
        if (e?.target?.result) {
          const xmlDoc = parser.parseFromString(
            e.target.result as string,
            "text/xml"
          );
          const geoJson = toGeoJSON.gpx(xmlDoc);
          if (geoJson && geoJson.features && geoJson.features.length > 0) {
            const { slopes } = getSlopesAndElevationSeriesFromGeojsonFeature(geoJson.features[0]);
            setGpxSlopes(slopes);
          }
        }
      };
      reader.readAsText(file);
  }

  return state !== "divide" ? (
    <ImageColumGrid
      image={`${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {state === "type" && <NewPlanType onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} />}
      {state === "race" && <NewPlanRace onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} />}
      {state === "add_race_confirmation" && <NewPlanRaceRequestConfirmation />}
      {state === "own" && <NewPlanOwn onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} setGpxFile={handleSetGpxFile} />}

      <Snackbar open={error} autoHideDuration={5000} onClose={() => setError(false)} message="There was an error saving the plan. Please try again" />
    </ImageColumGrid>
  ) : <NewPlanDivideOwn onStateChange={handleStateChange} setPlanData={setPlanData} plan={plan} userGpxFile={gpxFile} loading={isSavingPlan} />
});

export default NewPlan;