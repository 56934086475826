import { Box, TextField, Typography, styled } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useIntl } from "react-intl";
import { minutesToHHMM } from "../../utils/minutesToHHMM";
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { useEffect, useState } from "react";

dayjs.extend(localizedFormat);

const ParagraphLine = styled("span")(({ theme }) => ({
    verticalAlign: "baseline",
    height: 1,
    flexGrow: 1,
    flexShrink: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginLeft: "0.5em",
    marginRight: "0.5em"
}));

const SmallTextField = styled(TextField)(({ theme }) => ({
    maxWidth: theme.spacing(6),
    "& input": {
        fontSize: "0.875rem"
    }
}));

const PlannedTimeBox = styled(Box)(({ theme }) => ({
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
    marginTop: theme.spacing(0),
    padding: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.divider}`,
}));

export interface TimeBoxProps {
    hours?: number;
    minutes?: number;
    onChangeHours: (hours: number) => void;
    onChangeMinutes: (minutes: number) => void;
    startDate: Date;
    totalTimeBefore: number;
    totalTime: number;
}

const TimeBox: React.FC<TimeBoxProps> = observer(({ hours, minutes, onChangeHours, onChangeMinutes, startDate, totalTime, totalTimeBefore }) => {

    const intl = useIntl();

    const [hoursStr, setHours] = useState<string>(hours ? hours.toString() : "");
    const [minutesStr, setMinutes] = useState<string>(minutes ? minutes.toString() : "");

    useEffect(() => {
        setHours(hours ? hours.toString() : "");
        setMinutes(minutes ? minutes.toString() : "");
    }, [hours, minutes]);

    const onChangeHoursStr = (hours: string) => {
        if (hours === "") {
            setHours("");
            onChangeHours(0);
        } else {
            const hoursNum = parseInt(hours);
            if (!isNaN(hoursNum) && hoursNum >= 0) {
                setHours(hours);
                onChangeHours(hoursNum);
            }
        }
    };

    const onChangeMinutesStr = (minutes: string) => {
        if (minutes === "") {
            setMinutes("");
            onChangeMinutes(0);
        } else {
            const minutesNum = parseInt(minutes);
            if (!isNaN(minutesNum) && minutesNum >= 0 && minutesNum < 60) {
                setMinutes(minutes);
                onChangeMinutes(minutesNum);
            }
        }
    }

    return (
        <Box px={1}>
            <PlannedTimeBox display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2" fontWeight="bold">{intl.formatMessage({ id: "data.plannedTimeForSectionTitle" })}</Typography>
                <Box display="flex" alignItems="baseline">
                    <SmallTextField hiddenLabel variant="standard" size="small" type="number" value={hoursStr} onChange={(ev) => onChangeHoursStr(ev.target.value)} autoFocus /><Typography variant="body2" marginRight={1}>h:</Typography>
                    <SmallTextField hiddenLabel variant="standard" size="small" type="number" value={minutesStr} onChange={(ev) => onChangeMinutesStr(ev.target.value)} /><Typography variant="body2">min</Typography>
                </Box>
            </PlannedTimeBox>
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: "data.finishTimeForSection" })}</Typography>
                <ParagraphLine />
                <Typography variant="body2" fontWeight="bold">{dayjs(startDate).add(totalTimeBefore + (hours || 0) * 60 + (minutes || 0), "minutes").locale(intl.locale).format("lll")}</Typography>
            </Box>
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: "data.totalPlannedTime" })}</Typography>
                <ParagraphLine />
                <Typography variant="body2" fontWeight="bold">{minutesToHHMM(totalTime)}</Typography>
            </Box>
            <Box display="flex" alignItems="baseline" justifyContent="space-between" marginY={1}>
                <Typography variant="body2">{intl.formatMessage({ id: "data.totalFinishTime" })}</Typography>
                <ParagraphLine />
                <Typography variant="body2" fontWeight="bold">{dayjs(startDate).add(totalTime, "minutes").locale(intl.locale).format("lll")}</Typography>
            </Box>
        </Box>
    );
});

export default TimeBox;