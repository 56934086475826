import { useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";

export default function useRestricted(permission?: string) {

    const { userService } = useContext(AppContext);
    const navigate = useNavigate();

    const location = useLocation();
    const currentPath = location?.pathname;

    useEffect(() => {
        if (userService.isLoadingUser) {
            return;
        }

        if (!userService.user) {
            navigate("/sign-in", { state: { from: currentPath } });
        } else if (permission && !userService.user.permissions?.includes(permission)) {
            navigate("/");
        }

    }, [currentPath, navigate, userService.isLoadingUser, userService.user, permission]);

    return userService.isLoadingUser;
}