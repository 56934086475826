import { Feature } from 'geojson';
import { GeoJSON2SVG } from 'geojson2svg';

export const defaultSvg = `<svg fill="currentColor" viewBox="0 0 256 256" id="Flat" xmlns="http://www.w3.org/2000/svg">
<path d="M200,164a36.05663,36.05663,0,0,0-33.93579,24H72a28,28,0,0,1,0-56h96a44,44,0,0,0,0-88H72a12,12,0,0,0,0,24h96a20,20,0,0,1,0,40H72a52,52,0,0,0,0,104h94.06421A35.99779,35.99779,0,1,0,200,164Zm0,48a12,12,0,1,1,12-12A12.01312,12.01312,0,0,1,200,212Z"/>
</svg>`;

export const latlngSVG = (latLng: [number, number][], numSamples: number) => {

    const currentCoordinates = latLng.filter((_, index) => {
        if (index % (Math.ceil(latLng.length / numSamples)) === 0) {
            return true;
        }
        return false;
    });

    const geoJson: Feature = {
        'type': "Feature",
        'geometry': {
            'coordinates': currentCoordinates,
            'type': 'LineString',
        },
        'properties': {
        }
    };

    const converter = new GeoJSON2SVG();
    const svgStrings = converter.convert(geoJson, { output: 'path' });

    if (svgStrings.length === 0) {
        return '';
    }

    const svgPath = svgStrings[0];

    const values = svgPath.substring(1);

    let w = 0;
    let h = 0;

    values.split(' ').forEach((value) => {
        const [x, y] = value.split(',');
        w = Math.max(w, parseFloat(x));
        h = Math.max(h, parseFloat(y));
    });

    return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${w} ${h}" fill="none">
    <path stroke="currentColor" d="${svgPath}" stoke="currentColor"/>
    </svg>`
}
