export function arrangeWithMinimumDistance(arr: number[], distance: number) {
    const sortedArr = arr.sort((a, b) => a - b);
    const newArr = [sortedArr[0]];

    for (let i = 1; i < sortedArr.length; i++) {
        if (sortedArr[i] - newArr[newArr.length - 1] >= distance) {
            newArr.push(sortedArr[i]);
        } else {
            newArr.push(newArr[newArr.length - 1] + distance);
        }
    }

    return newArr;
}