import { makeAutoObservable } from "mobx";
import FirebaseService from "./FirebaseService";
import { collection, deleteDoc, doc, getDocs, orderBy, query, setDoc, where } from "firebase/firestore";

export type NutritionProduct = {
    id?: string;
    name: string;
    calories: number;
    protein: number;
    carbs: number;
    fat: number;
    caffeine: number;
    sodium: number;
    userId: string;
};

export default class NutritionProductsService {

    get nutritionProducts() {
        return this._nutritionProducts;
    }

    get isLoadingNutritionProducts() {
        return this._isLoadingNutritionProducts;
    }

    private _firebaseService: FirebaseService;
    
    private _nutritionProducts: NutritionProduct[] = [];
    private _isLoadingNutritionProducts = false;
    

    constructor(firebaseService: FirebaseService) {

        this._firebaseService = firebaseService;

        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    async loadNutritionProducts(userId: string) {
        if (this._isLoadingNutritionProducts) {
            return;
        }

        this._isLoadingNutritionProducts = true;
        const db = this._firebaseService.db;

        const collectionRef = collection(db, `nutritionProducts`);
        const q = query(collectionRef, orderBy("name", "asc"), where('userId', '==', userId));

        const querySnapshot = await getDocs(q);
        this._nutritionProducts = querySnapshot.docs.map(
            (docSnapshot) => {
                const nutritionProductData = docSnapshot.data();
                return nutritionProductData as NutritionProduct;
            });
        this._isLoadingNutritionProducts = false;
    }

    async upsertNutritionProduct(nutritionProduct: NutritionProduct) {
        const db = this._firebaseService.db;

        if (nutritionProduct.id) {
            const documentRef = doc(db, `nutritionProducts/${nutritionProduct.id}`);
            return setDoc(documentRef, nutritionProduct);
        }

        const collectionRef = collection(db, "nutritionProducts");
        return setDoc(doc(collectionRef), nutritionProduct);
    }

    async deleteNutritionProduct(nutritionProductId: string) {
        const db = this._firebaseService.db;
        const documentRef = doc(db, `nutritionProducts/${nutritionProductId}`);

        return deleteDoc(documentRef);
    }
}