import { Box, Typography, styled } from '@mui/material';

const BetaText = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  bottom: -15,
  right: -15,
  [theme.breakpoints.down('md')]: {
    fontSize: 10,
    bottom: -8,
    right: -8,
  },
}));

interface LogoProps {
  width: number;
}

export default function Logo({width}: LogoProps) {

  return (
    <Box position="relative">
      <img
          style={{ width }}
          src={`${process.env.PUBLIC_URL}/images/logo.png`}
          alt="Time on Trails"
      />
      <BetaText variant="body2">BETA</BetaText>
    </Box>
  );
}