import { makeAutoObservable } from "mobx";
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyCQF42toeqAKCz3nsTSloxXM0ZaQ-bKhPQ",
    authDomain: "trail-tools.firebaseapp.com",
    projectId: "trail-tools",
    storageBucket: "trail-tools.appspot.com",
    messagingSenderId: "444221127725",
    appId: "1:444221127725:web:53284eabe3068376a9cca4",
    measurementId: "G-FYK8ENNZK1"
};

export default class FirebaseService {

    private _firebaseApp = initializeApp(firebaseConfig);

    private _auth = getAuth();

    private _db = getFirestore();

    private _storage = getStorage();

    get auth() {
        return this._auth;
    }

    get db() {
        return this._db;
    }

    get storage() {
        return this._storage;
    }

    constructor() {

        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }
}