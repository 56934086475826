import { useContext, useEffect, useState } from "react";
import { IntlProvider } from "react-intl";
import English from "../lang/en.json";
import Spanish from "../lang/es.json";
import { AppContext } from "./AppContext";
import { observer } from "mobx-react-lite";

const LangWrapper: React.FC< { children: React.ReactNode }> = observer((props) => {

    const { userService } = useContext(AppContext);

    const navigatorLocale = navigator.language.split(/[-_]/)[0];
    const [locale, setLocale] = useState(navigatorLocale);
    const [messages, setMessages] = useState(navigatorLocale === "es" ? Spanish : English);

    useEffect(() => {
        if (userService.user) {
            const newLocale = userService.user.language || navigatorLocale;
            setNewLocale(newLocale);
        }
    }, [userService.user, navigatorLocale]);

    const setNewLocale = (newLocale: string) => {
        setLocale(newLocale);
        setMessages(newLocale === "es" ? Spanish : English);
    }

    return (<IntlProvider messages={messages} locale={locale}>
        {props.children}
    </IntlProvider>);
});

export default LangWrapper;