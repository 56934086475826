import * as React from 'react';
import Grid from '@mui/material/Grid';
import { Box } from '@mui/material';
import Logo from './Logo';

interface ImageColumGridProps {
  image: string;
  imageLabel?: React.ReactNode;
  children: React.ReactNode;
  hideLogo?: boolean;
}

export default function ImageColumGrid({image, imageLabel, children, hideLogo}: ImageColumGridProps) {

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid
        item
        md={7}
        sx={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: 'no-repeat',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          position: 'relative',
          display: { xs: "none", md: "block" }
        }}
      >
        {!hideLogo && <Box display="flex" justifyContent="center" marginTop={4}>
          <Logo width={200} />
        </Box>}
        {imageLabel}
      </Grid>
      <Grid item xs={12} md={5}>
        {children}
      </Grid>
    </Grid>
  );
}