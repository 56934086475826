import * as React from 'react';
import useRestricted from '../hooks/useRestricted';
import { observer } from 'mobx-react-lite';
import { AppContext } from '../contexts/AppContext';
import { useNavigate, useParams } from 'react-router-dom';
import TempoPlanner from '../components/plans/TempoPlanner';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Snackbar, styled, useMediaQuery, useTheme } from '@mui/material';
import { PlotContainer } from '../components/common/PlotContainer';
import { Map } from '../components/plans/Map';
import { getLatLngForDistance } from '../utils/UnitsUtils';
import { StyleControl } from '../components/plans/StyleControl';
import { useIntl } from 'react-intl';

const PlanPanel = styled(Box)(({ theme }) => ({
  borderRight: `solid 1px ${theme.palette.divider}`,
  width: 800,
  [theme.breakpoints.down('md')]: {
    width: "100%",
  }
}));

const Planner: React.FC = observer(() => {

  useRestricted();
  const intl = useIntl();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { userService, plansService, mapService } = React.useContext(AppContext);

  const { userId, planId } = useParams();

  const navigate = useNavigate();

  const [openMobileAlert, setOpenMobileAlert] = React.useState<boolean>(!isUpMD);

  const [error, setError] = React.useState<string | null>(null);

  const [currentSection, setCurrentSection] = React.useState<number>(0);

  const plan = plansService.plan;

  React.useEffect(() => {
    if (!userService.user?.id || !planId || !userId || (userService.user?.id !== userId)) {
      if (!userService.isLoadingUser) {
        navigate("/plans");
      }
      return;
    }

    if (plan && plan.id === planId) {
      return;
    }

    plansService.loadPlan(userId, planId).then((plan) => {
      if (plan) {
        plansService.parseCurrentPlanGpxFile().then((sucess) => {
          if (!sucess) {
            setError("error.gpxFileError");
            navigate("/plans");
          }
        });
      } else {
        navigate("/plans");
      }
    });
  }, [userService.isLoadingUser, plansService, planId, userId, userService.user?.id, navigate, plan]);

  React.useEffect(() => {
    if (plansService.bbox && isUpMD) {
      mapService.init(plansService.bbox);
    }
  }, [plansService.bbox, mapService, isUpMD]);

  const handleGraphHover = (distance: number | null) => {
    if (!distance) {
      return;
    }
    const [lng, lat] = getLatLngForDistance(distance, plansService.latlng, plansService.distancePoints)
    mapService.setPointPostion(lat, lng);
  }

  if (!plan) {
    return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
  }

  return (
    plan && (
      <Box display="flex" height={isUpMD ? "100vh" : "auto"} overflow="hidden">
          <PlanPanel>
            <TempoPlanner
              plan={plan}
              setPlanData={plansService.setPlanData}
              currentSection={currentSection}
              changeSection={setCurrentSection}
              sectionsData={plansService.sectionsData}
              plot={
                <Box paddingX={1} zIndex={10}>
                  <PlotContainer
                    id="section-plot"
                    data={plansService.elevationSeries.filter(e => e.distance <= plansService.sectionsData[currentSection].endDistance && e.distance >= plansService.sectionsData[currentSection].startDistance)}
                    dimensions={{ width: 500, height: 200, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
                    breaks={[]}
                    setHoveredDistance={handleGraphHover}
                    selectionBoxBottom={true}
                  />
                </Box>
              }
            />
          </PlanPanel>
          {isUpMD && <Box display="flex" flexDirection="column" width="100%">

            <Box flexGrow={1} position="relative">
              <Map />
              <StyleControl />
            </Box>
            <Box padding={1} zIndex={10}>
              <PlotContainer
                id="full-plot"
                data={plansService.elevationSeries}
                dimensions={{ width: 500, height: 300, margin: { top: 20, right: 0, bottom: 30, left: 40 } }}
                breaks={plansService.sections}
                setHoveredDistance={handleGraphHover}
              />
            </Box>
          </Box>}

        <Snackbar
          open={plansService.isSavingPlan}
          message={intl.formatMessage({ id: "data.saving" })}
          ContentProps={{
            sx: {
              background: "#ffffff",
              color: "#000000"
            }
          }}
        />

        <Snackbar
          open={plansService.hasSaveError}
          message={intl.formatMessage({ id: "data.savingError" })}
          autoHideDuration={6000}
          ContentProps={{
            sx: {
              background: "#e51f1f"
            }
          }}
        />

        <Dialog
          open={openMobileAlert}
          onClose={() => setOpenMobileAlert(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth="xs"
        >
          <DialogTitle id="alert-dialog-title">
            {intl.formatMessage({ id: "plans.mobileAlertTitle" })}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {intl.formatMessage({ id: "plans.mobileAlertText" })}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenMobileAlert(false)}>
              {intl.formatMessage({ id: "action.ok" })}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  );
});

export default Planner;