import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useIntl } from 'react-intl';
import useRestricted from '../hooks/useRestricted';
import { WorkspacePremium } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import BillingAddress from '../components/payment/BillingAddress';
import ImageColumGrid from '../components/common/ImageColumGrid';
import Link from '@mui/material/Link';
import { AppContext } from '../contexts/AppContext';
import CircularProgress from '@mui/material/CircularProgress';
import { observer } from 'mobx-react-lite';
import { productTokens, verifyPaymentUrl } from '../services/OrdersService';

const Payment: React.FC = observer(() => {
  useRestricted();

  const intl = useIntl();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const { userService, ordersService } = React.useContext(AppContext);

  const product = searchParams.get('product');
  const returnData = searchParams.get('returnData');
  const orderNumber = searchParams.get('order');
  const code = searchParams.get('code');

  const [verifyPaymentLoading, setVerifyPaymentLoading] = React.useState<boolean>(false);
  const [paymentSuccess, setPaymentSuccess] = React.useState<boolean>(false);
  const [paymentError, setPaymentError] = React.useState<number | null>(null);

  React.useEffect(() => {
    if (paymentSuccess || paymentError || verifyPaymentLoading) {
      return;
    }
    const verifyPayment = async () => {
      try {

        if (code !== "1") {
          setPaymentError(500);
          setVerifyPaymentLoading(false);
          return;
        }

        const response = await fetch(verifyPaymentUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            email: userService.user?.email,
            orderNumber,
            returnData
          })
        });

        const data = await response.json();

        if (!data.success) {
          setVerifyPaymentLoading(false);
          navigate("/checkout");
        }

        if (data.success && !!userService.user?.id && !!orderNumber && !!product) {
          const orderExists = await ordersService.orderExists(userService.user.id, orderNumber);
          if (orderExists === "error") {
            setPaymentError(500);
            setVerifyPaymentLoading(false);
          } else {
            if (!orderExists) {
              await ordersService.upsertOrder(userService.user.id, orderNumber);
              await userService.updateUserData({ ...userService.user, tokens: (userService.user.tokens || 0) + productTokens[product] });
              setVerifyPaymentLoading(false);
              setPaymentSuccess(true);
            } else {
              setVerifyPaymentLoading(false);
              navigate("/checkout");
            }
          }
        }
      } catch (error) {
        setPaymentError(500);
        setVerifyPaymentLoading(false);
        console.error(error);
      }
    }

    if (!!returnData && !!orderNumber && !!userService.user && !!product) {

      setVerifyPaymentLoading(true);
      verifyPayment();
    }
  }, [code, returnData, orderNumber, userService.user, product, ordersService, userService, navigate, paymentSuccess, paymentError, verifyPaymentLoading]);


  const goToPlans = () => {
    navigate("/plans");
  }

  const contactSupport = () => {
    window.open(`mailto: support@timeontrails.com?subject=Error on payment&body=I have a problem with the payment, orderNumber: ${orderNumber}.`);
  }

  return (
    <ImageColumGrid
      image={`${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {!!product && (!returnData || !orderNumber) ? <BillingAddress product={product} /> :
        (
          (verifyPaymentLoading || !userService.user) ? (
            <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) :
            paymentError ? (
              <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
              >
                <Typography component="h2" variant="h4" color="text.primary">
                  {intl.formatMessage({ id: "payment.error" })}
                </Typography>
                <Typography variant="body1" color="text.secondary" marginTop={2}>
                  {intl.formatMessage({ id: "payment.errorDescription" }, { icon: <WorkspacePremium /> })} <br />
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={contactSupport}
                >
                  {intl.formatMessage({ id: "payment.contactSupport" })}
                </Button>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={goToPlans}
                >
                  {intl.formatMessage({ id: "payment.goToPlans" })}
                </Button>
              </Box>
            ) : (
              <Box
              sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
              >
                <Typography component="h2" variant="h4" color="text.primary">
                  {intl.formatMessage({ id: "payment.thankyou" })}
                </Typography>
                <Typography variant="body1" color="text.secondary" marginTop={2}>
                  {intl.formatMessage({ id: "payment.result" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>{userService.user?.tokens} <WorkspacePremium /></Box>, b: (chunks: React.ReactNode) => <strong>{chunks}</strong> })} <br />
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  onClick={goToPlans}
                >
                  {intl.formatMessage({ id: "payment.goToPlans" })}
                </Button>
              </Box>
            )
        )
      }
    </ImageColumGrid>
  );
})

export default Payment;