import * as React from 'react';
import SignIn from '../components/auth/SignIn';
import SignUp from '../components/auth/SignUp';
import ForgotPassword from '../components/auth/ForgotPassword';
import { Link, Typography } from '@mui/material';
import ImageColumGrid from '../components/common/ImageColumGrid';
import { useLocation } from 'react-router-dom';

export default function Auth() {

  const location = useLocation();
  const currentPath = location?.pathname;

  return (
    <ImageColumGrid
      image={`${process.env.PUBLIC_URL}/images/auth-background.jpg`}
    >
      {currentPath === "/sign-in" && <SignIn />}
      {currentPath === "/sign-up" && <SignUp />}
      {currentPath === "/forgot-password" && <ForgotPassword />}
    </ImageColumGrid>
  );
}