import { makeAutoObservable } from "mobx";
import FirebaseService from "./FirebaseService";
import { addDoc, collection, doc, getDocs, query, setDoc } from "firebase/firestore";
import { getBlob, ref, uploadBytes } from "firebase/storage";

export type Race = {
    id: string,
    name: string;
    main: string;
    date: Date;
    gpxFiles: RaceGpxMetadata[];
    createdAt: Date;
    createdBy: string;
    website: string;
    distance: number;
    elevationGain: number;
    elevationLoss: number;
};

export type RaceGpxSlope = {
    startDistance: number;
    endDistance: number;
    type: "up" | "down" | "flat"
};

export type RaceGpxMetadata = {
    name: string;
    date: Date;
    aidStations: {
        name?: string;
        distance: number;
    }[];
    slopes: RaceGpxSlope[];
};

export type RaceRequest = {
    raceName: string;
    raceWebsite: string;
    raceDistance: string;
    userId: string;
    userEmail: string;
    createdAt: Date;
};

export default class RacesService {

    get races() {
        return this._races;
    }

    get isLoadingRaces() {
        return this._isLoadingRaces;
    }

    private _firebaseService: FirebaseService;

    private _races: Race[] = [];
    private _isLoadingRaces = false;

    constructor(firebaseService: FirebaseService) {

        this._firebaseService = firebaseService;
        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    async loadRaces() {
        if (this._isLoadingRaces) {
            return;
        }

        this._isLoadingRaces = true;
        const db = this._firebaseService.db;

        const collectionRef = collection(db, "races");
        const q = query(collectionRef);

        const querySnapshot = await getDocs(q);
        this._races = querySnapshot.docs.map(
            (docSnapshot) => {
                const raceData = docSnapshot.data();
                const gpxFiles = raceData.gpxFiles ? raceData.gpxFiles.map((gpxFile: any) => { return {...gpxFile, date: gpxFile.date.toDate()} }) : [];
                return { ...raceData, date: raceData.date.toDate(), gpxFiles } as Race
            });
        this._isLoadingRaces = false;
    }

    async addRaceRequest(raceRequest: RaceRequest) {
        const db = this._firebaseService.db;
        const collectionRef = collection(db, "raceRequests");

        return addDoc(collectionRef, raceRequest);
    };

    async upsertRace(race: Race) {
        const db = this._firebaseService.db;
        const documentnRef = doc(db, `races/${race.id}`);

        return setDoc(documentnRef, race);
    }

    async addGpxFile(raceId: string, gpxMetadata: RaceGpxMetadata, file: File) {

        const storage = this._firebaseService.storage;
        const storageRef = ref(storage, `${raceId}/${gpxMetadata.date.getTime()}.gpx`);

        return uploadBytes(storageRef, file);
    }

    async getGpxFile(raceId: string, gpxMetadata: RaceGpxMetadata) {
        const storage = this._firebaseService.storage;
        const storageRef = ref(storage, `${raceId}/${gpxMetadata.date.getTime()}.gpx`);
        return getBlob(storageRef).then((gpxFile) =>
            new File([gpxFile], raceId),
        ).catch(() => {
            return null;
        });
    }
}