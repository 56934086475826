import { observer } from "mobx-react-lite";
import useRestricted from "../hooks/useRestricted";
import { Box, Button, CircularProgress, Grid, LinearProgress, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import React from "react";
import { PlanItem } from "../components/plans/PlanItem";
import { AddOutlined } from "@mui/icons-material";
import { useIntl } from "react-intl";
import InfiniteScroll from "react-infinite-scroll-component";
import Logo from "../components/common/Logo";
import TokensLeft from "../components/common/TokensLeft";
import NoTokensDialog from "../components/common/NoTokensDialog";

const Home: React.FC = observer(() => {

  useRestricted();

  const intl = useIntl();

  const navigate = useNavigate();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  const { plansService, userService } = React.useContext(AppContext);

  const [loadingStarted, setLoadingStarted] = React.useState(false);
  const [noTokensDialogOpen, setNoTokensDialogOpen] = React.useState(false);

  useEffect(() => {
    if (!userService.user?.id) {
      return;
    }

    plansService.loadPlans(userService.user.id, true);
    setLoadingStarted(true);
  }, [plansService, userService.user?.id]);

  useEffect(() => {
    if (!plansService.isLoadingPlans && loadingStarted && !plansService.plans.length) {
      setLoadingStarted(false);
      navigate("/plans/new");
    }
  }, [plansService.isLoadingPlans, loadingStarted, navigate, plansService.plans.length]);

  const handleClickPlan = (id: string) => {
    if (!userService.user?.id) {
      return;
    }
    navigate(`/plans/${userService.user.id}/${id}`);
  }

  const handleNewPlan = () => {
    if (!userService.user?.tokens) {
      setNoTokensDialogOpen(true);
      return;
    }
    navigate("/plans/new");
  }

  if (!plansService.plans.length) {
    return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
  }

  return (
    <>
      <Box paddingX={4} paddingTop={4} display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h4">{intl.formatMessage({ id: "nav.plans" })}</Typography>
        {isUpMD && (<Logo width={100} />)}
        <Button variant="contained" color="primary" onClick={handleNewPlan} startIcon={<AddOutlined />}>
          {intl.formatMessage({ id: "plans.newPlan" })}
        </Button>
      </Box>
      <TokensLeft />
      {!!userService.user?.id &&
        (<InfiniteScroll
          dataLength={plansService.plans.length}
          next={() => {
            plansService.loadPlans(userService.user!.id!);
          }}
          hasMore={plansService.hasMorePlans}
          scrollThreshold={1}
          loader={<Box height={100} width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>}
        >
          <Grid container spacing={4} padding={4}>
            {
              plansService.plans.map((plan) => (
                <PlanItem key={plan.id} plan={plan} onClick={() => handleClickPlan(plan.id)} />
              ))
            }
          </Grid>
        </InfiniteScroll>)
      }
      <NoTokensDialog open={noTokensDialogOpen} onClose={() => setNoTokensDialogOpen(false)} />
    </>
  );
});

export default Home;
