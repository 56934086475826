import React, { createContext, useMemo } from 'react';
import FirebaseService from "../services/FirebaseService";
import UserService from "../services/UserService";
import { useNavigate } from 'react-router-dom';
import RacesService from '../services/RacesService';
import PlansService from '../services/PlansService';
import MapService from '../services/MapService';
import NutritionProductsService from '../services/NutritionProductsService';
import OrdersService from '../services/OrdersService';

export interface AppContextType {
    firebaseService: FirebaseService;
    userService: UserService;
    racesService: RacesService;
    plansService: PlansService;
    nutritionProductsService: NutritionProductsService;
    ordersService: OrdersService;
    mapService: MapService;
}

interface AppProviderProps {
    children: React.ReactNode;
}

export const AppContext = createContext<AppContextType>(
    {} as AppContextType
);

export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

    const navigate = useNavigate();



    const value = useMemo(() => {
        const firebaseService = new FirebaseService();

        const userService = new UserService(firebaseService, navigate);
        userService.init();

        const racesService = new RacesService(firebaseService);

        return {
            firebaseService: new FirebaseService(),
            userService: userService,
            racesService: racesService,
            plansService: new PlansService(firebaseService, racesService),
            mapService: new MapService(),
            ordersService: new OrdersService(firebaseService),
            nutritionProductsService: new NutritionProductsService(firebaseService)
        }

    }, [navigate]);

    return (
        <AppContext.Provider value={value}>
            {children}
        </AppContext.Provider>
    );
};
