import { makeAutoObservable } from "mobx";
import FirebaseService from "./FirebaseService";
import { doc, getDoc, setDoc } from "firebase/firestore";

export const productPrices: Record<string, string> = {
    9: "5.00",
    7: "20.00",
    5: "30.00",
}

export const productTokens: Record<string, number> = {
  9: 1,
  7: 5,
  5: 10,
}

export const paymentUrl = "https://processpayment-7dno2wbsya-uc.a.run.app";
export const verifyPaymentUrl = "https://verifypayment-7dno2wbsya-uc.a.run.app";

export default class OrdersService {

    private _firebaseService: FirebaseService;

    constructor(firebaseService: FirebaseService) {

        this._firebaseService = firebaseService;
        makeAutoObservable(this, undefined, { autoBind: true, deep: false })
    }

    async orderExists(userId: string, orderNumber: string): Promise<boolean | "error"> {

        const db = this._firebaseService.db;
        const docRef = doc(db, `orders/${userId}-${orderNumber}`);
        return getDoc(docRef).then((docSnapshot) => {
            return docSnapshot.exists();
        }).catch(() => {
            return "error";
        });
    };

    async upsertOrder(userId: string, orderNumber: string) {
        const db = this._firebaseService.db;
        const documentRef = doc(db, `orders/${userId}-${orderNumber}`);

        return setDoc(documentRef, {userId, orderNumber});
    }
}