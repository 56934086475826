import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
    GridRowsProp,
    GridRowModesModel,
    GridRowModes,
    DataGrid,
    GridColDef,
    GridToolbarContainer,
    GridActionsCellItem,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowEditStopReasons,
    GridSlots,
} from '@mui/x-data-grid';
import {
    randomId,
} from '@mui/x-data-grid-generator';
import { AppContext } from '../../contexts/AppContext';
import { observer } from 'mobx-react-lite';
import { CircularProgress, Snackbar } from '@mui/material';
import { useIntl } from 'react-intl';

interface EditToolbarProps {
    setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
    setRowModesModel: (
        newModel: (oldModel: GridRowModesModel) => GridRowModesModel,
    ) => void;
}

function EditToolbar(props: EditToolbarProps) {

    const intl = useIntl();
    const { setRows, setRowModesModel } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, { id, name: '', calories: 0, carbs: 0, protein: 0, fat: 0, sodium: 0, caffeine: 0, isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: 'name' },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                {intl.formatMessage({ id: "action.addProduct" })}
            </Button>
        </GridToolbarContainer>
    );
}


const NutritionProductsTable: React.FC = observer(() => {

    const intl = useIntl();

    const { nutritionProductsService, userService } = React.useContext(AppContext);
    const [rows, setRows] = React.useState<GridRowsProp>(nutritionProductsService.nutritionProducts || []);
    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (!userService.user?.id) {
            return;
        }
        nutritionProductsService.loadNutritionProducts(userService.user.id)
    }, [nutritionProductsService, userService.user?.id]);

    React.useEffect(() => {
        if (nutritionProductsService.nutritionProducts) {
            setRows(nutritionProductsService.nutritionProducts);
        }
    }, [nutritionProductsService.nutritionProducts]);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(rows.filter((row) => row.id !== id));
        nutritionProductsService.deleteNutritionProduct(id.toString());
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        if (!userService.user?.id) {
            return updatedRow;
        }
        const nutritionProduct = { id: "", name: "", calories: 0, carbs: 0, protein: 0, fat: 0, sodium: 0, caffeine: 0, userId: userService.user.id };
        nutritionProductsService.upsertNutritionProduct({ ...nutritionProduct, ...updatedRow }).catch(() => {
            setError("error.savingError")
            handleEditClick(newRow.id)();
        }
        );
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const columns: GridColDef[] = [
        { field: 'name', headerName: intl.formatMessage({ id: "data.name" }), width: 180, editable: true },
        {
            field: 'calories',
            headerName: intl.formatMessage({ id: "data.kcal" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'carbs',
            headerName: intl.formatMessage({ id: "data.carbs" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'protein',
            headerName: intl.formatMessage({ id: "data.protein" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'fat',
            headerName: intl.formatMessage({ id: "data.fat" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'sodium',
            headerName: intl.formatMessage({ id: "data.sodium" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        {
            field: 'caffeine',
            headerName: intl.formatMessage({ id: "data.caffeine" }),
            type: 'number',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
        },
        /*{
            field: 'icon',
            headerName: intl.formatMessage({ id: "data.icon" }),
            type: 'singleSelect',
            //width: 80,
            align: 'left',
            headerAlign: 'left',
            editable: true,
            valueOptions: [
                { label: <IceSkating />, value: "IceSkating"}
            ]
        },*/
        {
            field: 'actions',
            type: 'actions',
            headerName: intl.formatMessage({ id: "data.actions" }),
            width: 100,
            cellClassName: 'actions',
            getActions: ({ id }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            icon={<SaveIcon />}
                            label={intl.formatMessage({ id: "action.save" })}
                            sx={{
                                color: 'primary.main',
                            }}
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            icon={<CancelIcon />}
                            label={intl.formatMessage({ id: "action.cancel" })}
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        icon={<EditIcon />}
                        label={intl.formatMessage({ id: "action.edit" })}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <GridActionsCellItem
                        icon={<DeleteIcon />}
                        label={intl.formatMessage({ id: "action.delete" })}
                        onClick={handleDeleteClick(id)}
                        color="inherit"
                    />,
                ];
            },
        },
    ];

    if (nutritionProductsService.isLoadingNutritionProducts) {
        return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
    }

    return (
        <Box
            sx={{
                height: 500,
                width: '100%',
                '& .actions': {
                    color: 'text.secondary',
                },
                '& .textPrimary': {
                    color: 'text.primary',
                },
            }}
        >
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                autosizeOptions={{ expand: true }}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar as GridSlots['toolbar'],
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel },
                }}
            />
            <Snackbar
                open={!!error}
                message={intl.formatMessage({ id: "data.savingError" })}
                autoHideDuration={6000}
                ContentProps={{
                    sx: {
                        background: "#e51f1f"
                    }
                }}
            />
        </Box>
    );
});

export default NutritionProductsTable;