import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { appTheme } from './themes/themes';
import Home from './pages/Home';
import Root from './pages/Root';
import ErrorPage from './pages/ErrorPage';
import Search from './pages/Search';
import { AppProvider } from './contexts/AppContext';
import Account from './pages/Account';
import NewPlan from './pages/NewPlan';
import Auth from './pages/Auth';
import AddRace from './pages/AddRace';
import Planner from './pages/Planner';
import LangWrapper from './contexts/LangWrapper';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Checkout from './pages/Checkout';
import Payment from './pages/Payment';
import Insta from './pages/Insta';
import Help from './pages/Help';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppProvider>
      <LangWrapper>
        <Root />
      </LangWrapper>
    </AppProvider>,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Home /> },
      {
        path: "plans", element: <Home />,
      },
      { path: "plans/new", element: <NewPlan /> },
      { path: "plans/:userId/:planId", element: <Planner /> },
      { path: "insta/:userId/:planId", element: <Insta /> },
      {
        path: "search",
        element: <Search />,
      },
      {
        path: "account",
        element: <Account />,
      },
      {
        path: "checkout",
        element: <Checkout />,
      },
      {
        path: "payment",
        element: <Payment />,
      },
      {
        path: "sign-in",
        element: <Auth />,
      },
      {
        path: "sign-up",
        element: <Auth />,
      },
      {
        path: "forgot-password",
        element: <Auth />,
      },
      {
        path: "add-race",
        element: <AddRace />,
      },
      {
        path: "help",
        element: <Help />,
      }
    ],
  },
]);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode >
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
