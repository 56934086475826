import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useIntl } from 'react-intl';
import useRestricted from '../hooks/useRestricted';
import { WorkspacePremium } from '@mui/icons-material';
import { createSearchParams, useNavigate } from 'react-router-dom';

export default function Checkout() {
    useRestricted();

    const intl = useIntl();
    const navigate = useNavigate();

    const tiers = [
        {
            title: <Box display="inline-flex" alignItems="center" gap={0.5}>{intl.formatMessage({ id: "checkout.type1" })}: 1 x <WorkspacePremium /></Box>,
            price: '9',
            description: [
                intl.formatMessage({ id: "checkout.neverExpire" }),
            ],
            buttonText: intl.formatMessage({ id: "checkout.buyNow" }),
            buttonVariant: 'contained',
        },
        {
            title: <Box display="inline-flex" alignItems="center" gap={0.5}>{intl.formatMessage({ id: "checkout.type2" })}: 5 x <WorkspacePremium /></Box>,
            price: '7',
            description: [
                intl.formatMessage({ id: "checkout.save10" }),
                intl.formatMessage({ id: "checkout.neverExpirePlural" }),
            ],
            buttonText: intl.formatMessage({ id: "checkout.buyNow" }),
            buttonVariant: 'contained',
        },
        {
            title: <Box display="inline-flex" alignItems="center" gap={0.5}>{intl.formatMessage({ id: "checkout.type3" })}: 10 x <WorkspacePremium /></Box>,
            price: '5',
            description: [
                intl.formatMessage({ id: "checkout.save40" }),
                intl.formatMessage({ id: "checkout.neverExpirePlural" }),
            ],
            buttonText: intl.formatMessage({ id: "checkout.buyNow" }),
            buttonVariant: 'contained',
        },
    ];

    const goToPayment = (price: string) => {
        navigate({
            pathname: "/payment",
            search: `?${createSearchParams({product: price})}`,
        })
    }

    return (
        <Container
            id="pricing"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    {intl.formatMessage({ id: "checkout.title" })}
                </Typography>
                <Typography variant="body1" color="text.secondary" marginTop={2}>
                    {intl.formatMessage({ id: "checkout.subtitle1" }, { icon: <WorkspacePremium /> })} <br />
                    {intl.formatMessage({ id: "checkout.subtitle2" })}
                </Typography>
            </Box>
            <Grid container spacing={3} alignItems="center" justifyContent="center">
                {tiers.map((tier) => (
                    <Grid
                        item
                        key={tier.price}
                        xs={12}
                        sm={12}
                        md={4}
                    >
                        <Card
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4,
                            }}
                        >
                            <CardContent>
                                <Box
                                    sx={{
                                        mb: 1,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Typography component="h3" variant="h6">
                                        {tier.title}
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'baseline'
                                    }}
                                >
                                    <Typography component="h3" variant="h2">
                                        <s>${tier.price}</s> ${Math.ceil(+tier.price/2)}
                                    </Typography>
                                    <Typography component="h3" variant="h6">
                                        <Box display="inline-flex" alignItems="center" gap={0.5}>&nbsp; {intl.formatMessage({ id: "checkout.per" })} <WorkspacePremium /></Box>
                                    </Typography>
                                </Box>
                                <Divider
                                    sx={{
                                        my: 2,
                                        opacity: 0.2,
                                        borderColor: 'grey.500',
                                    }}
                                />
                                {tier.description.map((line) => (
                                    <Box
                                        key={line}
                                        sx={{
                                            py: 1,
                                            display: 'flex',
                                            gap: 1.5,
                                            alignItems: 'center',
                                        }}
                                    >
                                        <CheckCircleRoundedIcon
                                            sx={{
                                                width: 20,
                                                color: 'primary.main',
                                            }}
                                        />
                                        <Typography
                                            component="text"
                                            variant="subtitle2"
                                        >
                                            {line}
                                        </Typography>
                                    </Box>
                                ))}
                            </CardContent>
                            <CardActions>
                                <Button
                                    fullWidth
                                    variant={tier.buttonVariant as 'outlined' | 'contained'}
                                    //href={tier.buttonLink}
                                    onClick={() => goToPayment(tier.price)}
                                >
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}