const processCoordinates = function (coords: any, bounds: [number, number, number, number]) {
    if (Array.isArray(coords[0])) {
        coords.map((c: any) => processCoordinates(c, bounds));
    } else {
        bounds[0] = Math.min(bounds[0], coords[0]);
        bounds[1] = Math.min(bounds[1], coords[1]);
        bounds[2] = Math.max(bounds[2], coords[0]);
        bounds[3] = Math.max(bounds[3], coords[1]);
    }
};

export const getBoundsOfGeojson = (geojson: any) => {
    
    const bounds: [number, number, number, number] = [Infinity, Infinity, -Infinity, -Infinity];

    geojson.features.forEach((f: any) => {
        if (f.geometry && f.geometry.coordinates) {
            processCoordinates(f.geometry.coordinates, bounds);
        }
    });
    return bounds;
}