import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NewPlanState } from '../../pages/NewPlan';
import { AddOutlined, ArrowBack } from '@mui/icons-material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Plan } from '../../services/PlansService';
import { useIntl } from 'react-intl';
import { RaceGpxMetadata } from '../../services/RacesService';

export interface NewPlanOwnProps {
    onStateChange: (newState: NewPlanState) => void;
    setPlanData: (newPlanData: Partial<Plan>) => void;
    setGpxFile: (file: File) => void;
    plan: Partial<Plan>;
}

const NewPlanOwn: React.FC<NewPlanOwnProps> = ({ onStateChange, setPlanData, setGpxFile, plan }) => {

    const intl = useIntl();

    const [currentFile, setCurrentFile] = React.useState<File>();
    const [planGpxFile, setPlanGpxFile] = React.useState<RaceGpxMetadata>({
        name: "",
        date: new Date(),
        aidStations: [],
        slopes: []
    });

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            setGpxFile(event.target.files[0]);
            setCurrentFile(event.target.files[0]);
            const newPlanGpxFile = {
                ...planGpxFile,
                name: event.target.files[0].name
            };
            setPlanGpxFile(newPlanGpxFile);
            setPlanData({ gpxFile: newPlanGpxFile });
        }
    }

    const handleChangeDate = (newValue: dayjs.Dayjs | null) => {
        const date = newValue?.toDate();
        if (!date) {
            return;
        }
        const newPlanGpxFile = {
            ...planGpxFile,
            date
        };
        setPlanData({ date: newValue?.toDate(), gpxFile: newPlanGpxFile });
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => onStateChange("type")}>
                {intl.formatMessage({ id: "nav.back" })}
            </Button>
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.newPlanTypeOwn" })}
            </Typography>
            <Box sx={{ mt: 1 }}>
                <Button
                    component="label"
                    variant="outlined"
                    fullWidth
                    sx={{ my: 1 }}
                >
                    {currentFile ? intl.formatMessage({ id: "plans.uploadedGpx" }, { filename: currentFile.name }) : intl.formatMessage({ id: "plans.uploadGpx" })}
                    <input
                        className="form-control"
                        id="myGPX"
                        type="file"
                        accept=".gpx"
                        onChange={handleFileChange}
                        hidden
                    />
                </Button>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={intl.formatMessage({ id: "form.adventureDate" })}
                        sx={{ mt: 2, mb: 1, width: "100%" }}
                        value={plan.date ? dayjs(plan.date) : undefined}
                        onChange={handleChangeDate}
                    />
                </LocalizationProvider>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!plan.date || !currentFile}
                    onClick={() => onStateChange("divide")}
                >
                    {intl.formatMessage({ id: "nav.next" })}
                </Button>
            </Box>
        </Box >
    );
};

export default NewPlanOwn;