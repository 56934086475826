import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ArrowBack } from '@mui/icons-material';
import { AppContext } from '../../contexts/AppContext';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress } from '@mui/material';
import countries from "./countries_states_cities.json";
import { paymentUrl, productPrices } from '../../services/OrdersService';

type Country = {
    id: number;
    iso2: string;
    name: string;
    translations: {
        es: string
    };
    states: State[];
}

type State = {
    id: number;
    state_code: string;
    name: string;
}

const BillingAddress: React.FC<{ product: string }> = ({ product }) => {

    const navigate = useNavigate();
    const intl = useIntl();

    const { userService } = React.useContext(AppContext);

    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [country, setCountry] = React.useState<Country | null>(null);

    const onChangeCountry = (event: SelectChangeEvent<string>) => {
        const iso2 = event.target.value;
        const selectedCountry = (countries as Country[]).find(c => c.iso2 === iso2)
        setCountry(selectedCountry || null);
    }

    const handleSignUp = async (event: React.FormEvent<HTMLFormElement>) => {
        setLoading(true);
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        if (!data.get('firstName') || !data.get('lastName') || !data.get('address') || !data.get('country') || !data.get('state') || !data.get('city') || !data.get('postcode')) {
            setError("error.allFieldsRequired");
            setLoading(false);
            return;
        }
        try {
            const response = await fetch(paymentUrl, {
                method: "POST", // or 'PUT'
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "redirect": window.location.origin + "/payment?product=" + product,
                    "amount": productPrices[product],
                    "currency": "USD",
                    "billToFirstName": data.get('firstName'),
                    "billToLastName": data.get('lastName'),
                    "billToAddress": data.get('address'),
                    "billToAddress2": data.get('address'),
                    "billToCity": data.get('city'),
                    "billToState": data.get('state'),
                    "billToZipPostCode": data.get('postcode'),
                    "billToCountry": data.get('country'),
                    "billToTelephone": "0000",
                    "billToEmail": userService.user?.email,
                }),
            });

            const responseData = await response.json();
            if (responseData.url) {
                setLoading(false);
                window.open(responseData.url, "_self");
            } else {
                setError("error.unknown");
                setLoading(false);
            }
        } catch (error) {
            setError("error.unknown");
            setLoading(false);
        }
    };

    if (!userService.user) {
        return (<Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
        </Box>)
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => navigate("/checkout")}>
                {intl.formatMessage({ id: "nav.back" })}
            </Button>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "payment.billingAddress" })}
            </Typography>
            <Box component="form" noValidate onSubmit={handleSignUp} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label={intl.formatMessage({ id: "form.firstName" })}
                    name="firstName"
                    autoComplete="firstName"
                    autoFocus
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label={intl.formatMessage({ id: "form.lastName" })}
                    name="lastName"
                    autoComplete="lastName"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="address"
                    label={intl.formatMessage({ id: "form.address" })}
                    name="address"
                    autoComplete="address"
                    onChange={() => setError(null)}
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="country-select-label">{intl.formatMessage({ id: "form.country" })}</InputLabel>
                    <Select
                        labelId="country-select-label"
                        id="country"
                        name="country"
                        label={intl.formatMessage({ id: "form.country" })}
                        onChange={onChangeCountry}
                    >
                        {
                            (countries as Country[]).map(
                                (country) => <MenuItem value={country.iso2}>{country.name}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="normal">
                    <InputLabel id="states-select-label">{intl.formatMessage({ id: "form.state" })}</InputLabel>
                    <Select
                        labelId="states-select-label"
                        id="state"
                        name="state"
                        label={intl.formatMessage({ id: "form.state" })}
                        onChange={() => setError(null)}
                        disabled={!country}
                    >
                        {
                            (country?.states || [] as State[]).map(
                                (state) => <MenuItem value={state.state_code}>{state.name}</MenuItem>
                            )
                        }
                        {
                            (country?.states.length === 0) && <MenuItem value={country.iso2}>{country.name}</MenuItem>
                        }
                    </Select>
                </FormControl>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="city"
                    label={intl.formatMessage({ id: "form.city" })}
                    name="city"
                    onChange={() => setError(null)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="postcode"
                    label={intl.formatMessage({ id: "form.postcode" })}
                    name="postcode"
                    autoComplete="postcode"
                    onChange={() => setError(null)}
                />

                {
                    error && <Alert severity="error">{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" }) })}</Alert>
                }
                <LoadingButton
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    loading={loading}
                >
                    {intl.formatMessage({ id: "payment.goToPayment" })}
                </LoadingButton>
            </Box>
        </Box>
    );
};

export default BillingAddress;