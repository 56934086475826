import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { NewPlanState } from '../../pages/NewPlan';
import { AddOutlined, ArrowBack, WorkspacePremium } from '@mui/icons-material';
import { Plan, Section } from '../../services/PlansService';
import { LoadingButton } from '@mui/lab';
import { useIntl } from 'react-intl';
import { AppContext } from '../../contexts/AppContext';
import { Point } from '../../types/visualization';
import { getElevation, getSlopesAndElevationSeriesFromGeojsonFeature, round } from '../../utils/UnitsUtils';
import { PlotContainer, PlotType } from '../common/PlotContainer';
import Slider from '@mui/material/Slider';
import { Alert, ButtonGroup, CircularProgress } from '@mui/material';
import { arrangeWithMinimumDistance } from '../../utils/arrangeMinimunDistance';
import { latlngSVG } from '../../utils/latlngSVG';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const toGeoJSON = require("@mapbox/togeojson");

export interface NewPlanDivideOwnTypeProps {
    onStateChange: (newState: NewPlanState) => void;
    setPlanData: (newPlanData: Partial<Plan>) => void;
    plan: Partial<Plan>;
    loading?: NewPlanState;
    userGpxFile?: File;
}

const NewPlanDivideOwn: React.FC<NewPlanDivideOwnTypeProps> = ({ onStateChange, setPlanData, plan, loading, userGpxFile }) => {

    const intl = useIntl();

    const { racesService } = React.useContext(AppContext);

    const [error, setError] = React.useState<string | null>(null);

    const [elevationSeries, setElevationSeries] = React.useState<Point[]>([]);
    const [distancePoints, setDistancePoints] = React.useState<number[]>([]);

    const [plotType, setPlotType] = React.useState<PlotType>("steep");
    const [numberOfSections, setNumberOfSections] = React.useState<number>(1);
    const [value, setValue] = React.useState<number[]>([]);

    const handleSetValue = (newValue: number[], svg: string = "") => {
        setValue(newValue);

        const sections: Section[] = [];

        for (let i = 0; i < newValue.length - 1; i++) {
            sections.push({ startDistance: newValue[i] * 1000, endDistance: newValue[i + 1] * 1000 });
        }

        if (!plan.raceId) {
            const elevations = getElevation(elevationSeries.map((p) => p.elevation));
            setPlanData({
                sections, svg: svg || plan.svg,
                distance: round(distancePoints[distancePoints.length - 1] / 1000, 2), elevationGain: elevations.elevationGain, elevationLoss: elevations.elevationLoss
            });
        } else {
            setPlanData({ sections, svg: svg || plan.svg });
        }
    };

    const handleFileChange = (file: File) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const parser = new DOMParser();
            if (e?.target?.result) {
                const xmlDoc = parser.parseFromString(
                    e.target.result as string,
                    "text/xml"
                );
                const geoJson = toGeoJSON.gpx(xmlDoc);
                if (geoJson && geoJson.features && geoJson.features.length > 0) {
                    const { elevationSeries, distancePoints, altitudePoints, latlng } = getSlopesAndElevationSeriesFromGeojsonFeature(geoJson.features[0]);
                    setElevationSeries(elevationSeries);
                    setDistancePoints(distancePoints);
                    const aidStations = plan.gpxFile?.aidStations.map(a => round(a.distance / 1000, 1)) || [];
                    handleSetValue([0, ...aidStations, round(distancePoints[distancePoints.length - 1] / 1000, 1)], latlngSVG(latlng, 50));
                    setNumberOfSections(aidStations.length);

                    if (!altitudePoints.find(a => !!a)) {
                        setError("error.altitudeError");
                    }
                }
            }
        };
        reader.readAsText(file);
    };

    React.useEffect(() => {
        if (plan.raceId && plan.gpxFile) {
            racesService.getGpxFile(plan.raceId, plan.gpxFile).then((gpxFile) => {
                if (gpxFile) {
                    handleFileChange(gpxFile);
                }
                else
                    setError("error.gpxFileError");
            });
        } else if (userGpxFile) {
            handleFileChange(userGpxFile);
        }
    }, [plan.gpxFile, plan.raceId, racesService, userGpxFile]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        let newValues = newValue as number[];
        if (!newValues.includes(0)) {
            newValues[0] = 0;
        }
        if (!newValues.includes(round(distancePoints[distancePoints.length - 1] / 1000, 1))) {
            newValues[newValues.length - 1] = round(distancePoints[distancePoints.length - 1] / 1000, 1);
        }
        newValues = arrangeWithMinimumDistance([...newValues], 0.5);
        handleSetValue(newValues);
    };

    const addSection = () => {
        setNumberOfSections(numberOfSections + 1);
        const newValue = round(value[value.length - 2] + (round(distancePoints[distancePoints.length - 1] / 1000, 0) - value[value.length - 2]) / 2, 0);
        const newValues = [...value, newValue].sort((a, b) => a - b);
        handleSetValue(newValues);
    }

    const removeSection = () => {
        setNumberOfSections(numberOfSections - 1);
        const newValues = [...value.slice(0, value.length - 2), round(distancePoints[distancePoints.length - 1] / 1000, 1)];
        handleSetValue(newValues);
    }

    const onNext = () => {
        onStateChange("finish");
    }

    if (!elevationSeries.length || !distancePoints.length) {
        return <Box height="100vh" width="100%" display="flex" alignItems="center" justifyContent="center"><CircularProgress /></Box>;
    }

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Button startIcon={<ArrowBack />} sx={{ alignSelf: "flex-start" }} onClick={() => onStateChange(plan.raceId ? "race" : "own")}>
                {intl.formatMessage({ id: "nav.back" })}
            </Button>
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.addYourSections" })}
            </Typography>
            <Box sx={{ mt: 1 }}>

                {!!elevationSeries && !!elevationSeries.length &&
                    <>
                        <PlotContainer
                            id="divide-own-plot"
                            data={elevationSeries}
                            dimensions={{ width: 500, height: 300, margin: { top: 10, right: 10, bottom: 30, left: 40 } }}
                            breaks={value.slice(1, value.length - 1).map((v) => v * 1000)}
                        />
                        <Slider
                            value={value}
                            onChange={handleChange}
                            valueLabelDisplay="on"
                            min={0}
                            step={0.1}
                            max={round(distancePoints[distancePoints.length - 1] / 1000, 1)}
                            sx={{ marginTop: 5 }}
                        />
                        <ButtonGroup variant="contained" sx={{ margin: 2 }}>
                            <Button onClick={removeSection} disabled={value.length <= 2}>-</Button>
                            <Box display="flex" alignItems="center" justifyContent="center" paddingX={1}>
                                <Typography variant="body2">
                                    {intl.formatMessage({ id: "plans.sections" }, { numSections: numberOfSections })}
                                </Typography>
                            </Box>
                            <Button onClick={addSection} disabled={value[value.length - 1] - value[value.length - 2] < 1}>+</Button>
                        </ButtonGroup>
                    </>
                }


                {
                    error && <Alert severity="error" action={
                        error === "error.altitudeError" && <Button href="mailto:support@timeontrails.com" color="inherit" size="small">
                          {intl.formatMessage({ id: "payment.contactSupport" }, { numSections: numberOfSections })}
                        </Button>
                      }>{intl.formatMessage({ id: error, defaultMessage: intl.formatMessage({ id: "error.unknown" }) })}</Alert>
                }
                <LoadingButton
                    loading={loading === "finish"}
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    disabled={!elevationSeries || !elevationSeries.length || loading === "finish" || !!error}
                    onClick={onNext}
                >
                    {intl.formatMessage({ id: "nav.finish" })} (<Box display="inline-flex" alignItems="center" gap={0.5}>{intl.formatMessage({ id: "nav.useOne" })} <WorkspacePremium /></Box>)
                </LoadingButton>
            </Box>
        </Box >
    );
};

export default NewPlanDivideOwn;