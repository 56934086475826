import { ThemeOptions, createTheme } from '@mui/material/styles';
import "@maptiler/sdk/dist/maptiler-sdk.css";

const themeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: '#000000',
        },
        secondary: {
            main: '#689f38',
        },
    }
};

export const appTheme = createTheme(themeOptions);
