import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { AppContext } from '../../contexts/AppContext';
import { WorkspacePremium } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


export default function TokensLeft() {

  const { userService } = React.useContext(AppContext);
  const intl = useIntl();
  const navigate = useNavigate();

  const theme = useTheme();
  const isUpMD = useMediaQuery(theme.breakpoints.up('md'));

  if (!userService.user) {
    return null;
  }

  const goToCheckout = () => {
    navigate("/checkout");
  }

  return (
    <Box position="relative" display="flex" alignItems="center" flexDirection={isUpMD ? "row" : "column"} marginTop={3} marginBottom={-1} marginX={1} justifyContent="center" gap={1}>
      <Typography variant="body2" textAlign="center">
      {
        !userService.user.tokens ? intl.formatMessage({ id: "data.noTokens" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>{userService.user?.tokens} <WorkspacePremium /></Box>, b: (chunks: React.ReactNode) => <strong>{chunks}</strong> }) :
          userService.user.tokens > 1 ?
            intl.formatMessage({ id: "data.tokensLeftPlural" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>{userService.user?.tokens} <WorkspacePremium /></Box>, b: (chunks: React.ReactNode) => <strong>{chunks}</strong> }) :
            intl.formatMessage({ id: "data.tokensLeftSingular" }, { numTokens: <Box display="inline-flex" alignItems="center" gap={0.5}>{userService.user?.tokens} <WorkspacePremium /></Box>, b: (chunks: React.ReactNode) => <strong>{chunks}</strong> })
      }
      </Typography>
      <Button onClick={goToCheckout} size="small" variant="contained" endIcon={<WorkspacePremium />}>{intl.formatMessage({ id: "data.buyTokens" })}</Button>
    </Box>
  );
}