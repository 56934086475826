import { BakeryDining, BatteryChargingFull, BatteryFull, DinnerDining, EmojiFoodBeverage, LocalCafe, LocalDrink, LocalPizza, LunchDining, RamenDining, SoupKitchen, SportsBar, WaterDrop } from "@mui/icons-material";
import { NutritionProductPlan, PlannedNutrition } from "../services/PlansService";
import { NutritionProduct } from "../services/NutritionProductsService";

const nutritionLabelIconMap: Record<string, React.ReactNode> = {
    battery: <BatteryFull />,
    batteryEnergy: <BatteryChargingFull />,
    pizza: <LocalPizza />,
    burger: <LunchDining />,
    ramen: <RamenDining />,
    soup: <SoupKitchen />,
    bakery: <BakeryDining />,
    pasta: <DinnerDining />,
    water: <WaterDrop />,
    drink: <LocalDrink />,
    coffee: <LocalCafe />,
    tea: <EmojiFoodBeverage />,
    beer: <SportsBar />
}

export const getNutritionLabelsIconOptions = () => {
    return Object.keys(nutritionLabelIconMap).map((label) => {
        return {
            value: label,
            label: nutritionLabelIconMap[label],
        }
    });
}

export const getTotalSectionNutrition = (sectionNutrition: NutritionProductPlan[], nutritionProducts: NutritionProduct[]) => {
    return sectionNutrition.reduce((acc, nutritionPlanItem) => {
        if (nutritionPlanItem.productId === "water") {
            return {
                water: acc.water + nutritionPlanItem.quantity,
                calories: acc.calories,
                carbs: acc.carbs,
                protein: acc.protein,
                fat: acc.fat,
                sodium: acc.sodium,
                caffeine: acc.caffeine
            }
        }
        const nutritionProduct = nutritionProducts.find((product: NutritionProduct) => product.id === nutritionPlanItem.productId);
        if (!nutritionProduct) {
            return acc;
        }
        return {
            water: acc.water,
            calories: acc.calories + nutritionProduct.calories * nutritionPlanItem.quantity,
            carbs: acc.carbs + nutritionProduct.carbs * nutritionPlanItem.quantity,
            protein: acc.protein + nutritionProduct.protein * nutritionPlanItem.quantity,
            fat: acc.fat + nutritionProduct.fat * nutritionPlanItem.quantity,
            sodium: acc.sodium + nutritionProduct.sodium * nutritionPlanItem.quantity,
            caffeine: acc.caffeine + nutritionProduct.caffeine * nutritionPlanItem.quantity
        }
    }, {
        water: 0,
        calories: 0,
        carbs: 0,
        protein: 0,
        fat: 0,
        sodium: 0,
        caffeine: 0
    });
}

export const getTotalNutrition = (nutritionPlan: PlannedNutrition[], nutritionProducts: NutritionProduct[]) => {
    return nutritionPlan.reduce((acc, section) => {
        if (!section.products) {
            return acc;
        }
        const sectionNutrition = getTotalSectionNutrition(section.products, nutritionProducts);
        return {
            water: acc.water + sectionNutrition.water,
            calories: acc.calories + sectionNutrition.calories,
            carbs: acc.carbs + sectionNutrition.carbs,
            protein: acc.protein + sectionNutrition.protein,
            fat: acc.fat + sectionNutrition.fat,
            sodium: acc.sodium + sectionNutrition.sodium,
            caffeine: acc.caffeine + sectionNutrition.caffeine
        }
    }, {
        water: 0,
        calories: 0,
        carbs: 0,
        protein: 0,
        fat: 0,
        sodium: 0,
        caffeine: 0
    });
}

export const getProductList = (nutritionPlan: PlannedNutrition[], nutritionProducts: NutritionProduct[]) => {
    const list: {name: string, quantity: number}[] = [];
    nutritionPlan.forEach((section) => {
        if (!section.products) {
            return;
        }
        section.products.forEach((product) => {
            const nutritionProduct = nutritionProducts.find((p) => p.id === product.productId);
            if (!nutritionProduct) {
                return;
            }
            const existingProduct = list.find((p) => p.name === nutritionProduct.name);
            if (existingProduct) {
                existingProduct.quantity += product.quantity;
            } else {
                list.push({
                    name: nutritionProduct.name,
                    quantity: product.quantity
                });
            }
        });
    });
    return list;
}