import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useIntl } from 'react-intl';
import useRestricted from '../hooks/useRestricted';
import { WorkspacePremium } from '@mui/icons-material';
import { createSearchParams, useNavigate } from 'react-router-dom';

export default function Help() {

    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <Container
            id="pricing"
            sx={{
                pt: { xs: 4, sm: 12 },
                pb: { xs: 8, sm: 16 },
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: 3, sm: 6 },
            }}
        >
            <Box
                sx={{
                    width: { sm: '100%', md: '60%' },
                    textAlign: { sm: 'left', md: 'center' },
                }}
            >
                <Typography component="h2" variant="h4" color="text.primary">
                    {intl.formatMessage({ id: "help.title" })}
                </Typography>
                <Typography variant="body1" color="text.secondary" marginTop={2}>
                    {intl.formatMessage({ id: "help.contact" }, { icon: <WorkspacePremium /> })} <br />
                    <a href="mailto:support@timeontrails.com">support@timeontrails.com</a>
                </Typography>
            </Box>
        </Container>
    );
}