import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AddOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';

const NewPlanRaceRequestConfirmation: React.FC = () => {

    const intl = useIntl();

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                my: 8,
                mx: 4,
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
            }}
        >
            <Avatar sx={{ marginX: "auto", marginY: 1, bgcolor: 'secondary.main' }}>
                <AddOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
                {intl.formatMessage({ id: "plans.thanks" })}
            </Typography>

            <Typography variant="body2" sx={{ mt: 2 }}>
                <strong>{intl.formatMessage({ id: "plans.raceRequestConfirmation1" })}</strong>
            </Typography>
            <Typography variant="body2" sx={{ mt: 2 }}>
                <strong>{intl.formatMessage({ id: "plans.raceRequestConfirmation2" })}</strong>
            </Typography>
            <Typography variant="body2" sx={{ my: 2 }}>
                <strong>{intl.formatMessage({ id: "plans.raceRequestConfirmation3" })}</strong>
            </Typography>
            <Box>
                <Button
                    fullWidth
                    variant="contained"
                    sx={{ my: 1 }}
                    onClick={() => navigate("/")}
                >
                    {intl.formatMessage({ id: "plans.backToPlans" })}
                </Button>
            </Box>
        </Box >
    );
};

export default NewPlanRaceRequestConfirmation;